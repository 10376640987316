*,
*::before,
*::after {
  box-sizing: border-box;
}

.bg-themedark {
  background-color: #125d60;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Orbitron", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  text-align: left;
  background-color: #000000;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding-bottom: env(safe-area-inset-bottom);
  overflow-x: hidden;
  word-wrap: break-word;
  filter: hue-rotate(33deg);


  &::before {
    content: "";
    background-color: #000000;
    height: 100%;
    left: 0;
    opacity: .8;
    position: fixed;
    top: 0;
    width: 100%;
    background-image: url(../images/bgAnim.gif);
    opacity: 0;
    z-index: -1;
    transition: all 1.2s ease-in-out;
  }
}

body:not(.pageLoaded) {
  height: 100vh;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.bg-themedark {
  background-color: #125d60;
}

.bg-themelight {
  background-color: #01ffff;
}

.oc_row {
  display: flex;
  flex-wrap: wrap;
}

.oc_col6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 10px;
}

.oc_container {
  width: 100%;
  max-width: calc(100% - 80px);
  margin-left: auto;
  margin-right: auto;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }

// Dashboard Css Start
.cirLeftToRight {
  transform-origin: center;
  animation: keyLeftToRight 5s ease-in-out infinite alternate-reverse;
}

.cirLeftToRightR {
  transform-origin: center;
  animation: keyLeftToRight 10s ease-in-out infinite alternate;
}

.cirLeftToRightRS {
  transform-origin: center;
  animation: keyLeftToRight 20s ease-in-out infinite alternate;
}

.circleBox {
  position: relative;
  height: 50vh;
  width: auto;
  display: block;
  max-width: 50vw;
  margin: 0 auto;
  text-align: center;
  min-width: 310px;
  min-height: 310px;
  margin-top: -10vw;
  margin-bottom: -25vw;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  opacity: 0;
  transform-origin: center;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.1;
    transition: all 0.5s ease-in-out;
  }

  .centerlogo {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    height: 10vh;
    width: auto;
    max-width: 7vw;
    margin: 0 auto;
    text-align: center;
    min-width: 110px;
    min-height: 110px;
  }

  img {
    transform: scale(1);
    display: block;
    width: 100%;
    height: auto;
    animation: zoom-in-zoom-out 2s ease-out infinite;
  }

  @keyframes zoom-in-zoom-out {
    50% {
      transform: scale(1.1);
    }
  }

  .infobox {
    position: relative;
    margin-top: 2.5vw;

    .Typewriter {
      font-size: 40px;
      font-weight: 700;
      line-height: 1;
      margin: 0 0 30px;
      text-shadow: 2px 2px 50px #50dbff;

    }
  }

  h2 {
    margin: 0px 0px 20px;
    text-align: center;
    font-size: 2vw;
    color: #00ffff;
    text-transform: uppercase;
  }

  p {
    font-size: 1.5vw;
    margin: 0px 0px 10px;
    color: #08d5c6;
  }
}

@keyframes keyLeftToRight {
  to {
    transform: rotate(360deg);
  }
}

.cirRightToLeft {
  transform-origin: center;
  animation: keyRightToLeft 15s linear infinite;
}

@keyframes keyRightToLeft {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.righttoleft {
  transform-origin: center;
  animation: righttoleft 6s linear infinite reverse;
}

@keyframes righttoleft {
  to {
    transform: rotate(-360deg);
  }
}

.lefttoright {
  transform-origin: center;
  animation: righttoleft 6s linear infinite;
}

@keyframes lefttoright {
  to {
    transform: rotate(-360deg);
  }
}

.borderboxmain {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  min-height: 100vh;
  z-index: -1;
  opacity: 0;
  transition: all 1s ease-in-out;

  img {
    width: calc(100% - 2vw);
    height: auto;
    display: block;
    position: absolute;
    object-fit: contain;

    &.linetopleft {
      top: 1vw;
      left: 1vw;
      object-position: left;
    }

    &.linebottomright {
      bottom: 1vw;
      right: 1vw;
      object-position: right;
    }
  }
}

.dashboard {
  padding: 5vw;
  position: relative;

  // min-height: 100vh;
  .box {
    padding: 100px;
    width: 100%;
    background-color: #111111;
  }
}

.oc_row {
  .oc_mobile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1.5vw;
    opacity: 0;
    transition: all 1s ease-in-out;

    .innerbox {
      position: relative;
      height: 15vw;
      width: auto;
      display: block;
      max-height: 91px;
      min-height: 92px;
      margin-block: 3.5vw;
      padding-inline: 10px;

      svg {
        width: 100%;
        height: 100%;
        display: block;
      }

      .centerlogo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        max-width: 15vh;
        max-height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-50%, -50%);
        color: #ffffff;
        text-decoration: none;
      }

      h2 {
        margin: 10px 0px 10px;
        text-align: center;
        font-size: 18px;
        color: #00ffff;
        text-transform: uppercase;
      }
    }
  }
}

.middelBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 100px);
}

.boxBottomLeft {
  max-width: 400px;

  .innerbox {
    position: relative;
    height: 15vw;
    width: auto;
    display: block;
    max-width: 100%;
    margin: 0 auto;
    max-height: 115px;
    text-align: center;
    min-height: 115px;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }

    .centerlogo {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      max-width: 15vh;
      max-height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-decoration: none;
    }

    h2 {
      margin: 10px 0px 10px;
      text-align: center;
      font-size: 18px;
      color: #00ffff;
      text-transform: uppercase;
    }
  }
}


.progressbox {
  position: relative;
  margin-bottom: 30px;
  padding: 15px 50px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../images/boxframleft.svg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: left center;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("../images/boxframright.svg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: right center;
    z-index: -1;
  }

  .progressboxinr {
    margin-bottom: 50px;

    p {
      margin: 0px 0px 5px;
      text-transform: uppercase;
      padding-left: 10px;
      font-size: 18px;
    }
  }

  .progress {
    background-color: transparent;
    border-radius: 0px;
    height: 28px;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      font-size: 18px;
    }
  }

  .progress-bar-striped {
    background-image: linear-gradient(135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent);
  }

  .progress-bar {
    height: 28px;
    max-width: 0%;
    transition: all 1s ease-in-out;
    transition-delay: 1s;
  }
}

.boxTopLeft {
  position: relative;
  max-width: 600px;

  .chartBox {
    position: relative;
    text-align: center;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 70px;
      height: calc(100% - 20px);

      z-index: 1;
    }

    &::before {
      left: 0;
      background-image: linear-gradient(to right, #000000, transparent);
    }

    &::after {
      right: 0;
      background-image: linear-gradient(to left, #000000, transparent);
    }

    .chartjs-tooltip {
      display: none;
    }

    P {
      margin: 10px 0 0;
    }
  }

  .chartBoxInner {
    display: block;
    align-items: center;
    position: relative;
    animation: marqueeChart 10s linear infinite;
    opacity: 0;
    height: 68px;
  }

  @keyframes marqueeChart {
    0% {
      left: 0;
    }

    100% {
      left: -100%;
    }
  }

  .chartData {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;

    img {
      padding-left: 20px;
      width: 100%;
    }

    span {
      position: absolute;
      bottom: 15px;
      right: 20px;
    }
  }

  .boxline {
    margin-top: 50px;
    width: 100%;
    height: auto;
    display: block;
  }
}

.boxTopRight {
  max-width: 600px;
  margin-left: auto;
  position: relative;

  .progressCirclemain {
    margin-left: auto;
    align-items: flex-start;
  }

  .progrfour {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .progressCirclebox {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    padding: 0px 10px;

    .svgCirBox {
      position: relative;
      width: 112px;

      .mainbox {
        position: relative;
        margin: 0 auto;
        width: 112px;
        height: auto;

        img {
          height: 112px;
          width: 110px;
          // mix-blend-mode: difference;
          mix-blend-mode: hue;
        }

        .single-chart {
          width: 33%;
          justify-content: space-around;
        }

        .circular-chart {
          display: block;
          margin: 0px auto;
          max-width: 100%;
        }

        .circle-bg {
          fill: none;
          stroke: none;
          stroke-width: 3.5;
        }

        .circle {
          fill: none;
          stroke-width: 3.5px;
          stroke-linecap: square;
          // animation: progress 10s ease-out forwards;
          transition: all 1s ease-in-out;
        }

        // @keyframes progress {
        //   0% {
        //     stroke-dasharray: 0 100;
        //   }
        // }

        .circular-chart.orange .circle {
          stroke: #02ffff;
        }

        .percentage {
          fill: #666;
          font-family: sans-serif;
          font-size: 0.5em;
          text-anchor: middle;
        }

        .mainbox img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        svg.circular-chart {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 119px;
          height: 112px;
          z-index: -1;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;

      .clr {
        animation: col 2s linear 1;
      }

      .clr1 {
        animation-delay: 1s;
      }

      .clr2 {
        animation-delay: 1.5s;
      }

      .clr3 {
        animation-delay: 2s;
      }

      .clr4 {
        animation-delay: 2.5s;
      }

      .clr5 {
        animation-delay: 3s;
      }

      .clr6 {
        animation-delay: 3.5s;
      }

      .clr7 {
        animation-delay: 4s;
      }

      .clr8 {
        animation-delay: 4.5s;
      }

      .clr9 {
        animation-delay: 5s;
      }

      .clr10 {
        animation-delay: 5.5s;
      }
    }

    @keyframes col {
      0% {
        opacity: 0.5;
      }

      100% {
        opacity: 1;
      }
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      font-weight: bolder;
    }

    h3 {
      margin: 10px 0px 0px;
      text-align: center;
      font-size: 14px;
      color: #00b8bc;
    }
  }

  .chartData {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;

    img {
      padding-left: 20px;
      width: 100%;
    }

    span {
      position: absolute;
      bottom: 15px;
      left: 30px;
      font-size: 20px;
      word-spacing: 6px;
    }
  }

  .boxline {
    top: 50%;
    margin-top: 50px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: -1;

    &::after {
      content: "APR";
      top: 10px;
      left: 40px;
      width: 100%;
      position: absolute;
      font-size: 25px;
    }
  }
}

.boxBottomRight {
  position: relative;
  // max-width: 340px;
  height: 100%;
  width: 100%;
  margin-left: auto;
  // display: flex;
  align-items: center;

  // &::after {
  //   content: "LP Division";
  //   position: absolute;
  //   bottom: 50px;
  //   left: 0;
  //   font-size: 26px;
  //   word-spacing: 3px;
  // }

  .border_line {
      position: relative;

    &::before {
      content: "APR";
      top: 20px;
      left: 400px;
      width: 100%;
      position: absolute;
      font-size: 25px;
    }

    img {
      position: absolute;
      left: 390px;
      // bottom: -10px;
    }
  }

  .progressbox {
    position: relative;
    margin-bottom: 15px;
    padding: 15px 50px;
    max-width: 350px;
    width: 320px;
    margin-left: auto;
    height: 320px;
  }

  .progressboxinr {
    p {
      margin: 0px 0px 5px;
      text-transform: uppercase;
      padding-left: 10px;
      font-size: 15px;
    }

    .progress {
      background-color: transparent;
      border-radius: 0px;
      height: 24px;
      display: flex;
      align-items: center;
    }
  }
}

.connectBtnMain {
  transition: all 0.15s ease-in-out;

  .bg-button {
    display: inline-block;
    position: relative;
    background-color: transparent;
    color: #00d7c3;
    border: none;
    padding: 0px;


    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      color: #01ffff;
      z-index: 0;
    }

    svg {
      transition: all 0.15s ease-in-out;
      width: 100%;
      display: block;
      max-width: 250px;
    }
  }

  &.poolsBoxHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;

    .backIcon {
      text-decoration: none;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 22px;
      font-weight: 400;
      margin: 0px;
      color: #01ffff;
    }
  }
}

.connectBtnMain {
  svg:hover {
    // box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
    filter: drop-shadow(0px 0px 13px #00d7c3);
  }
}

.poolsBox {
  position: relative;
  padding: 50px 40px;
  width: 100%;
  background-color: #000000;
  margin-top: 10px;
  margin-bottom: 50px;
  min-height: 150px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-image: url('../images/poolbox/poolboxtopline.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-image: url('../images/poolbox/poolboxbottomline.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .poolsBoxInner {
    background-color: #002d2e;
    padding: 20px;
    border-radius: 2px;
  }

  .poolBoxDefault {
    text-align: center;
    padding: 30px 0px;

    svg {
      display: block;
      margin: 0 auto 15px;
      stroke: #01ffff;
    }

    p {
      font-size: 18px;
      margin: 0px;
      color: #01ffff;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
}

// Dashboard Css End

// swap css start
.swap {
  position: relative;

  .swap-container {
    .button-1 {
      background-color: transparent;
      // border: 3px solid #00d7c3;
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      color: #00d7c3;
      z-index: 99;
    }

    .button-1:hover {
      box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
      // border: 3px solid #00d7c3;
    }

    .swap-main-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      svg {
        width: 100%;
        display: block;
        height: auto;
      }

      .token-box-1 {
        position: relative;
        max-width: 600px;

        svg {
          position: relative;
          z-index: -1;
        }

        .main-box {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 9;
          transform: translate(-50%, -50%);
          justify-content: flex-start;
          width: 450px;
        }

        .form-group {
          display: flex;
          width: auto;
          margin-right: auto;
          flex-direction: column;
          width: 50%;
          justify-content: left;

          input[type="number"] {
            outline: none;
            background: none;
            border: none;
            font-size: 24px;
            color: #00ffff;
          }

          input[type="number"]::placeholder {
            color: #00ffff;
            background: transparent;
            outline: none;
          }

          input[type="text"]:focus {
            outline: none;
          }
        }

        label {
          display: inline-block;
          margin-bottom: 60px;
          font-size: 25px;
          color: #00ffff;
        }



        .token-balance {
          .token-currency {
            display: flex;
            align-items: center;
            cursor: pointer;

            .bg-logo {
              background: black;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-inline-end: 15px;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .token-box-2 {
        max-width: 95px;
        height: auto;
        margin-inline: auto;
        margin-block: 10px;

        svg {
          width: 100px;
          height: auto;
          transition: all 0.3s ease-in-out;

          &:hover {
            filter: drop-shadow(0px 0px 10px #00d7c3);
          }
        }
      }

      .token-box-3 {
        position: relative;
        max-width: 600px;

        svg {
          position: relative;
          z-index: -1;
        }

        .main-box {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 9;
          transform: translate(-50%, -50%);
          justify-content: flex-start;
          width: 450px;
        }

        .form-group {
          display: flex;
          width: auto;
          margin-right: auto;
          flex-direction: column;
          width: 50%;
          justify-content: left;

          input[type="number"] {
            outline: none;
            background: none;
            border: none;
            font-size: 24px;
            color: #00ffff;
          }

          input[type="number"]::placeholder {
            color: #00ffff;
            background: transparent;
            outline: none;
          }

          input[type="number"]:focus {
            outline: none;
          }
        }

        label {
          display: inline-block;
          margin-bottom: 60px;
          font-size: 25px;
          color: #00ffff;
        }

        .token-balance {
          .token-currency {
            display: flex;
            align-items: center;

            .bg-logo {
              background: black;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-inline-end: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .swapNetwork {
        position: relative;
        max-width: 600px;
        margin-top: 30px;

        .infoIcon {
          position: absolute;
          top: -2px;
          left: 0;
          fill: #ffffff;
          width: 25px;
        }

        .topBtnArrow {
          position: absolute;
          top: -2px;
          right: 0;
          fill: #ffffff;
          width: 22px;
          background-color: #ffffff;
          z-index: -1;
          border-radius: 50px;
        }

        .Collapsible__trigger {
          padding-left: 35px;
          width: 100%;
          display: block;
        }

        .Collapsible {
          position: relative;

          &::after {
            content: "";
          }
        }

        .Collapsible__contentOuter {
          margin-top: 25px;

          .Collapsible__contentInner {
            border: 1px solid #01ffff;
            border-radius: 10px;
            padding: 20px;

            p {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin: 0px 0px 10px;
              font-size: 14px;

              span {
                font-size: 13px;
              }
            }
          }
        }
      }

      .token-button {
        display: flex;
        width: 100%;
        margin-top: 50px;
        border: none;
        max-width: initial;

        span {
          position: absolute;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          color: #01ffff;
          z-index: 9;
          font-size: 20px;
        }

        svg {
          z-index: 0;
        }
      }
    }

    button {
      margin-left: auto;
      background: transparent;
      color: aqua;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      max-width: 300px;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        filter: invert(1);
        transition: all 0.3s ease-in-out;
      }

      span {
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        color: #01ffff;
        z-index: 0;
      }

      .bg-button {
        position: relative;
        transition: all 0.5s;
        z-index: 99;
        max-width: 380px;

        svg {
          width: 100%;
          height: 100%;

          .lefttoright {
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .bg-button:hover {
        filter: drop-shadow(0px 0px 13px #00d7c3);
      }

      &.active {
        img {
          transform: translate(-50%, -50%) scaleY(-1);
        }

        svg {
          .lefttoright {
            animation: righttoleft 6s linear infinite reverse;
          }
        }
      }
    }
  }
}

// swap css end

// nfts css start
.nfts {
  position: relative;

  .nfts-container {
    .search-box-content {
      display: flex;
      align-items: center;
      flex: 0 0 48%;
      max-width: 48%;

      .empty-space {
        display: block;
        width: 33.33%;
        height: auto;
      }

      .search-bar-box {
        margin-left: auto;
        display: flex;
        justify-content: center;

        .search-bar {
          display: flex;
          justify-content: flex-end;

          .mainLoginInput {
            text-align: center;

          }

          .mainLoginInput::-webkit-input-placeholder {
            font-family: FontAwesome;
            font-weight: normal;
            overflow: visible;
            vertical-align: top;
            display: inline-block !important;
            padding-left: 5px;
            padding-top: 2px;
            color: #008686;
          }

          .mainLoginInput::-moz-placeholder {
            font-family: FontAwesome;
            font-weight: normal;
            overflow: visible;
            vertical-align: top;
            display: inline-block !important;
            padding-left: 5px;
            padding-top: 2px;
            color: #008686;
          }

          .mainLoginInput:-ms-input-placeholder {
            font-family: FontAwesome;
            font-weight: normal;
            overflow: visible;
            vertical-align: top;
            display: inline-block !important;
            padding-left: 5px;
            padding-top: 2px;
            color: #008686;
          }
        }
      }

      .nfts-button {
        display: block;
        width: 33.33%;
      }
    }

    .nfts-outer-button-border {
      border: 2px solid #006F76;
      background: transparent;
      padding: 2px 5px;
      border-inline-width: 4px;
      position: relative;
      left: 8px;
      flex: 0 0 50%;
      max-width: 480px;

      .nfts-inner-button-border {
        border: 2px solid #002626;
        background: transparent;
        padding: 3px;
        border-inline-width: 4px;

        .nfts-button-grp {
          margin-inline: auto;

          .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
            background: #004646;
            outline: none;
          }

          ul {
            margin-bottom: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            justify-content: space-between;

            li {
              background-color: #002626;
              color: #01ffff;
              padding: 10px 24px;
              cursor: pointer;
              border: none;
              font-size: 18px;
              margin: 0;
              width: 100%;
              flex: 0 0 33.33%;
              max-width: 33.3%;
              text-align: center;
            }
          }

          button {
            background-color: #002626;
            color: #01ffff;
            padding: 10px 24px;
            cursor: pointer;
            float: left;
            border: none;
            font-size: 18px;
          }

          button:hover {
            background-color: #004646;
          }
        }

        .nfts-button-grp:after {
          content: "";
          clear: both;
          display: table;
        }
      }
    }

    .scroll-border {
      position: relative;
      width: 100%;
      margin: 0px auto;

      .scroll-shadow {
        display: none;
      }

      .scroll {
        position: relative;
      }
    }

    .scroll::-webkit-scrollbar {
      width: 5px;
      max-height: 50%;
    }

    .scroll::-webkit-scrollbar-track {
      background: #00A5A5;
      max-height: 50%;
      height: 50;
    }

    .scroll::-webkit-scrollbar-thumb {
      background: #00FFFF;
    }

    .nfts-content-area {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 0px auto 0;

      .nfts-box-1 {
        padding: 17px 15px;
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 20px);
        max-width: calc(50% - 20px);
        margin: 0px 10px 20px;

        &::before {
          position: absolute;
          content: "";
          background: url(../images/before.svg) center/contain no-repeat;
          background-size: 100%;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background-position: top center;
          z-index: -1;
        }

        &::after {
          position: absolute;
          content: "";
          background: url(../images/after.svg) center/contain no-repeat;
          background-size: 100%;
          height: 100%;
          width: 100%;
          bottom: 0;
          right: 0;
          background-position: bottom center;
          z-index: -1;
        }

        .nfts-border-1 {
          height: 100%;
          width: 100%;
          border: 2px solid #004646;
          background: #002D2E;

          .nfts-input-box {
            height: 100%;
            width: 100%;
            padding: 12px;

            .nfts-sub-input-box {
              background: #004849;
              height: 110px;
              width: 100%;
            }

            .nfts-volume-box {
              display: flex;
              justify-content: space-between;
              margin: 0 50px 0 35px;

              .volume-box-1 {
                svg {
                  margin-top: -10px;
                }
              }

              .volume-box-2 {
                span {
                  display: block;
                  text-align: end;
                  color: #01FFFF;
                }

                .span-1 {
                  font-size: 37px;
                  margin-bottom: 7px;
                }

                .span-2 {
                  font-size: 21px;
                }

              }
            }
          }
        }
      }
    }

    label {
      position: relative;
    }

    label::after {
      content: "";
      position: absolute;
      right: 0;
      top: -17px;
      bottom: 0;
      width: 433px;
      height: 74px;
      background: url("../images/button-clip.png") center / contain no-repeat;
      z-index: -1;
    }

    input {
      padding: 10px 30px;
    }

    input[type="search"] {
      background: #002626;
      outline: none;
      border: 1px solid #004747;
      font-size: 26px;
      flex-grow: 1;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }

    input[type="search"]::placeholder {
      color: #008686;
      z-index: 9;
      letter-spacing: 0.1rem;
      // font-weight: bold;

    }

    [type="search"] {
      outline-offset: none;
      margin: 0px auto;
      display: block;
      text-align: center;
      padding: 4px 18px;
      border-radius: 20px;
      border: 1px solid #707070;
      color: #008686;
    }

    .custom-field input:focus label::before {
      content: "";
      left: 55px;
    }

    .button-1 {
      background-color: transparent;
      border: 3px solid #00d7c3;
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      color: #00d7c3;
      z-index: 99;
    }

    .button-1:hover {
      box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
      border: 3px solid #00d7c3;
    }

    .nfts-main-box {
      width: max-content;
      margin-inline: auto;
      padding: 30px;
    }

    button {
      margin-left: auto;
      background: transparent;
      color: aqua;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;
      max-width: 300px;

      span {
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        color: #01ffff;
        z-index: 0;
        width: 100%;
      }

      .bg-button {
        position: relative;
        transition: all 0.5s;
        mix-blend-mode: hard-light;
        z-index: 99;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .bg-button:hover {
        mix-blend-mode: normal;
        box-shadow: 0 0 10px 0 cyan inset, 0 0 20px 2px cyan;
      }

      &.token-button {
        margin: 25px auto 0px;
        position: relative;

        span {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .bg-button {
          svg {
            margin: 0px;
          }
        }
      }
    }
  }
}

// nfts css end

// Gridlistview css start
.gridlistview {
  position: relative;

  .farming-container {
    .farming-button-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 9999;
      background-image: linear-gradient(#000000 60%, rgba(255, 0, 0, 0));
      padding-top: 12px;
      padding-bottom: 20px;

      .flex-btn-group {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 45%;
        max-width: 45%;

        .farming-outer-button-border {
          border: 2px solid #006F76;
          background: transparent;
          padding: 2px 6px;
          border-inline-width: 4px;
          margin-left: 0px;
          width: calc(100% - 160px);
          max-width: 250px;


          .farming-inner-button-border {
            border: 2px solid #002626;
            background: transparent;
            padding: 3px;
            border-inline-width: 4px;

            .farming-button-grp {
              margin-inline: auto;

              .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
                background: #004646;
                outline: none;
              }

              ul {
                margin-bottom: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;

                li {
                  background-color: #002626;
                  color: #01ffff;
                  padding: 10px 6px;
                  cursor: pointer;
                  border: none;
                  font-size: 18px;
                  margin: 0;
                  flex: 0 0 50%;
                  max-width: 50%;
                  text-align: center;

                }
              }


              button {
                background-color: #002626;
                color: #01ffff;
                padding: 15px 38px;
                cursor: pointer;
                float: left;
                border: none;
                font-size: 26px;
              }

              button:hover {
                background-color: #004646;
              }
            }

            .drop-down-btn {
              display: block;
            }

            .farming-button-grp:after {
              content: "";
              clear: both;
              display: table;
            }
          }
        }
      }

      .empty-area {
        width: 580px;
      }

      .view-btn-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 160px;

        .list-btn {
          margin-inline-end: 25px;

          button {
            margin-left: auto;
            background: transparent;
            color: aqua;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
          }
        }

        .gird-btn {
          margin-inline-end: 20px;

          button {
            border: none;
          }
        }
      }
    }

    .search-box-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 0 0 calc(55% - 175px);
      max-width: calc(55% - 175px);

      .empty-space {
        display: block;
        height: auto;
      }

      .search-bar-box {
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .search-bar {
          display: flex;
          justify-content: flex-end;

          .mainLoginInput {
            text-align: center;

          }

          .mainLoginInput::-webkit-input-placeholder {
            font-family: FontAwesome;
            font-weight: normal;
            overflow: visible;
            vertical-align: top;
            display: inline-block !important;
            padding-left: 5px;
            padding-top: 2px;
            color: #008686;
          }

          .mainLoginInput::-moz-placeholder {
            font-family: FontAwesome;
            font-weight: normal;
            overflow: visible;
            vertical-align: top;
            display: inline-block !important;
            padding-left: 5px;
            padding-top: 2px;
            color: #008686;
          }

          .mainLoginInput:-ms-input-placeholder {
            font-family: FontAwesome;
            font-weight: normal;
            overflow: visible;
            vertical-align: top;
            display: inline-block !important;
            padding-left: 5px;
            padding-top: 2px;
            color: #008686;
          }
        }
      }

      .nfts-button {
        display: block;
        width: 33.33%;
      }
    }

    .farming-outer-button-border-1 {
      border: 2px solid #006F76;
      background: transparent;
      padding: 2px 6px;
      border-inline-width: 4px;
      flex: 0 0 135px;
      max-width: 135px;
      margin-left: 10px;


      .farming-inner-button-border {
        border: 2px solid #002626;
        background: transparent;
        padding: 3px;
        border-inline-width: 4px;

        .farming-button-grp {
          margin-inline: auto;

          .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
            background: #004646;
            outline: none;
          }

          .btn-group {
            width: 100%;
          }

          ul {
            margin-bottom: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            top: calc(100% + 8px) !important;
            transform: none !important;
            right: -18px !important;
            left: unset !important;
            filter: drop-shadow(0px 1px 2px #00d7c3);

            li {
              background-color: #002626;
              color: #01ffff;
              padding: 10px 24px;
              cursor: pointer;
              border: none;
              font-size: 18px;
              margin: 0;

              .dropdown-item {
                padding: 10px;
                color: #fff;
              }

              .dropdown-item:hover {
                color: #01ffff;
                background-color: #004646;
              }

            }
          }


          button {
            background-color: #002626;
            color: #01ffff;
            padding: 8px 6px;
            cursor: pointer;
            float: left;
            border: none;
            font-size: 22px;
            border-radius: 0;
          }

          button:hover {
            background-color: #004646;
          }
        }

        .drop-down-btn {
          display: block;
        }

        .farming-button-grp:after {
          content: "";
          clear: both;
          display: table;
        }
      }
    }

    .scroll-border {
      position: relative;
      width: 100%;
      margin: 0px auto;

      .scroll-shadow {
        position: absolute;
        bottom: 0;
        right: 0px;
        width: 100%;
        height: 150px;
        background-image: linear-gradient(transparent, black);
        z-index: 1;
        max-width: 100%;
      }
    }

    .scroll::-webkit-scrollbar {
      width: 5px;
      max-height: 50%;
    }

    .scroll::-webkit-scrollbar-track {
      background: #00A5A5;
      max-height: 50%;
      height: 50;
    }

    .scroll::-webkit-scrollbar-thumb {
      background: #00FFFF;
    }

    .farming-content-area {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 20px auto 30px;

      .farming-box-1 {
        position: relative;
        height: 100%;
        border: 4px solid aqua;
        padding: 4px;
        background-color: #012728;
        margin: 0px 10px 30px;
        flex: 0 0 calc(100% / 3 - 20px);
        max-width: calc(100% / 3 - 20px);

        &::before {
          content: "";
          position: absolute;
          bottom: -6px;
          right: -4px;
          background-image: url("../images/farming-box-left-frame.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-size: contain;
          z-index: 0;
          transform: scaleX(-1);
          width: 54px;
          height: 94px;
          background-position: bottom right;
        }

        &::after {
          content: "";
          position: absolute;
          top: -10px;
          background-image: url("../images/farming-box-right-frame.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-size: contain;
          z-index: 0;
          transform: scaleX(-1);
          left: -5px;
          width: 74px;
          height: 184px;
          background-position: top left;
        }

        .frame-sub-box-1 {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          font-size: 25px;
          color: #01FFFF;
          text-transform: uppercase;

          .farming-logo-box {
            margin-right: 11px;
          }
        }

        .frame-sub-box-2 {
          padding-inline: 14px;

          .frame-input-sub-1 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .span-area-1 {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              text-transform: uppercase;

              .span-letter {
                margin-top: 8px;
              }
            }

            .span-area-2 {
              text-align: center;
              font-size: 16px;

              p {
                margin: 0;
                color: #01FFFF;

                img {
                  margin-inline: 9px;
                  display: none;
                }

                span {
                  display: block;
                  color: #fff;
                  margin-top: 8px;
                }
              }
            }
          }

          .frame-input-sub-button {
            display: none;
          }

          .frame-input-sub-2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 35px;

            .span-area-3 {
              p {
                margin-bottom: 0;
                font-size: 22px;

                .span-letter {
                  margin-right: 5px;
                  color: #01FFFF;
                }

                .span-number {
                  display: block;
                  margin-top: 8px;
                  font-size: 18px;
                  color: #01FFFF;
                }
              }
            }

            .span-area-4 {
              position: relative;
              display: block;
              height: 100%;
              width: 100%;
              max-width: 120px;

              .harvest-bg {
                background: url(../images/farming-harvest-btn.svg);
                padding: 4px;
                background-repeat: no-repeat;
                background-origin: padding-box;
                background-size: contain;
              }

              button {
                padding: 4px 10px;
                background: #004848;
                border: 1px solid #006364;
                font-size: 16px;
                display: initial;
              }
            }
          }

          .frame-input-sub-3 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 35px;

            .span-area-5 {
              p {
                margin-bottom: 0;
                font-size: 15px;
                line-height: 1.6rem;
                letter-spacing: 0.1rem;
                text-transform: capitalize;

                .span-letter {
                  display: block;
                  font-size: 24px;
                  margin-block: 9px;
                  color: #01FFFF;
                }

                .span-number {
                  display: block;
                  margin-top: 8px;
                }
              }
            }

            .span-area-6 {
              width: 100%;
              position: relative;
              display: flex;
              height: 100%;
              text-align: end;
              max-width: 120px;

              .harvest-bg {
                background: url(../images/farming-harvest-btn.svg);
                padding: 4px;
                background-repeat: no-repeat;
                background-size: contain;
              }

              button {
                padding: 4px 5px;
                background: #004848;
                border: 1px solid #006364;
                font-size: 16px;
                display: initial;
              }
            }
          }
        }

        .frame-sub-box-3 {
          margin-block: 25px;

          button {
            background: transparent;
            color: aqua;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            border: none;

            span {
              position: absolute;
              text-transform: uppercase;
              letter-spacing: 0.1rem;
              color: #01ffff;
              z-index: 0;
              font-size: 18px;
            }

            .bg-button {
              position: relative;
              transition: all 0.5s;
              mix-blend-mode: hard-light;
              z-index: 99;
              max-width: 280px;

              svg#Group_22317 {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    label {
      position: relative;
    }

    label::after {
      content: "";
      position: absolute;
      right: 0;
      top: -17px;
      bottom: 0;
      width: 100%;
      height: 74px;
      background: url("../images/button-clip.png") center / cover no-repeat;
      z-index: -1;
    }

    input {
      padding: 10px 30px;
    }

    input[type="search"] {
      background: #002626;
      outline: none;
      border: 1px solid #004747;
      font-size: 26px;
      flex-grow: 1;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }

    input[type="search"]::placeholder {
      color: #008686;
      z-index: 9;
      letter-spacing: 0.1rem;
      // font-weight: bold;

    }

    [type="search"] {
      outline-offset: none;
      margin: 0px auto;
      display: block;
      text-align: center;
      padding: 4px 18px;
      border-radius: 20px;
      border: 1px solid #707070;
      color: #008686;
      width: 100%;
    }

    .custom-field input:focus label::before {
      content: "";
      left: 55px;
    }

    .button-1 {
      background-color: transparent;
      border: 3px solid #00d7c3;
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      color: #00d7c3;
      z-index: 99;
    }

    .button-1:hover {
      box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
      border: 3px solid #00d7c3;
    }

    .nfts-main-box {
      width: max-content;
      margin-inline: auto;
      padding: 30px;
    }

    button {
      margin-left: auto;
      background: transparent;
      color: aqua;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;
      max-width: 300px;

      &:hover {
        svg {
          filter: drop-shadow(0px 0px 10px #00d7c3);
        }
      }

      span {
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        color: #01ffff;
        z-index: 0;
      }

      .bg-button {
        position: relative;
        transition: all 0.5s;
        mix-blend-mode: hard-light;
        z-index: 99;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .bg-button:hover {
        mix-blend-mode: normal;
        box-shadow: 0 0 10px 0 cyan inset, 0 0 20px 2px cyan;
      }
    }
  }
}

.gridlistview.listviewmode .farming-content-area {
  .farming-box-1 {
    display: flex;
    max-height: 100px;
    overflow: hidden;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    transition: all 0.5s;
    flex: 0 0 100%;
    max-width: 100%;

    .frame-sub-box-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      font-size: 25px;
      color: #01FFFF;
      text-transform: uppercase;
      flex: 0 0 20%;
    }

    .frame-sub-box-2 {
      padding-inline: 14px;
      display: flex;
      width: 100%;
      flex: 0 0 74%;
      flex-wrap: wrap;
      justify-content: space-around;

      .frame-input-sub-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 55%;
        margin-top: 14px;
      }

      .frame-input-sub-button {
        display: flex;
        align-items: center;
        margin-inline-start: 300px;
        z-index: 99;

      }

      .frame-input-sub-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        flex: 0 0 50%;

        .span-area-4 {
          height: auto;
        }
      }

      .frame-input-sub-3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .span-area-6 {
          height: auto;
        }
      }

    }

    .frame-sub-box-3 {
      display: table-row;
      width: 100%;
      flex: 0 0 100%;

      button {
        background: transparent;
        color: aqua;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        max-width: 400px;

        span {
          font-size: 22px;
        }

        .bg-button {
          position: relative;
          transition: all 0.5s;
          mix-blend-mode: hard-light;
          z-index: 99;
          max-width: 506px;
        }

      }

    }

  }

  .farming-box-1.height-slider {
    max-height: 400px;
  }
}

// Gridlistview css end

.boxTopLeft,
.boxTopRight,
.boxBottomLeft,
.boxBottomRight {
  transform: scale(0);
  transform-origin: top right;
  transition: all 1s ease-in-out;
  opacity: 0;
}

.boxTopLeft {
  transform-origin: bottom right;
}

.boxTopRight {
  transform-origin: bottom left;
}

.boxBottomLeft {
  transform-origin: top right;
}

.boxBottomRight {
  transform-origin: bottom left;
}


.pageLoaded {

  .boxTopLeft,
  .boxTopRight,
  .boxBottomLeft,
  .boxBottomRight {
    transform: scale(1);
    opacity: 1;
  }

  .circleBox {
    svg {
      opacity: 1;
    }
  }

  .progressbox .progress-bar {
    max-width: 100%;
  }

  .boxTopLeft .chartBoxInner {
    display: flex;
    opacity: 1;
  }

  &::before {
    opacity: 0.08;
  }

  .boxTopRight .progressCirclebox .svgCirBox .mainbox .circle {
    animation: progress 8s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .oc_row .oc_mobile,
  .borderboxmain {
    opacity: 1;
  }
}

.loadDash {
  .circleBox {
    transform: scale(1);
    opacity: 1;
  }
}

.nftsHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0px;
  padding-top: 15px;
  z-index: 9999;
  background-image: linear-gradient(#000000 60%, rgba(255, 0, 0, 0));
  padding-bottom: 50px;
}

.ox_container {
  width: 1320px;
  max-width: calc(100% - 10vw);
  margin: 0 auto;
  padding: 0px;
}

#root {
  min-height: 100vh;
}

.modal {
  &.newPositionMdl {
    z-index: 999999;

    .modal-dialog {
      max-width: calc(100% - 40px);
      width: 1040px;
      margin-left: auto;
      margin-right: auto;

      .modal-content {
        background-color: #012728;
        padding: 0px;
        font-family: "Poppins", sans-serif;
        border: none;
        box-shadow: 0px 0px 120px 110px #000000;
      }

      .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px;
        border-color: #274748;
        border-width: 1px;

        .btn-close {
          margin: 0px;
          flex: 0 0 16px;
          opacity: 1;
          max-width: 16px;
          filter: invert(1);
          order: 0;
        }

        .modal-title {
          text-align: center;
          order: 1;
          font-size: 18px;
          padding-left: 40px;
        }

        .headRight {
          order: 2;
          display: flex;
          align-items: center;
          flex: 0 0 auto;

          p {
            margin: 0px;
            font-size: 12px;
            color: #01ffff;
            cursor: pointer;
          }

          .btn-group {
            margin-left: 15px;

            button {
              background-color: transparent;
              border: none;
              color: #ffffff;
              padding: 0px;
              margin: 0px;
            }
          }
        }
      }
    }

    .titileBox {
      font-size: 16px;
      display: block;
      margin: 0 0 20px;
    }

    .popMaininrBox {
      .col-lg-6 {
        padding: 10px 30px;
      }
    }

    .selectPairBox {
      .btn-group {
        margin: 0px 0px 10px;
        flex: 0 0 50%;
        max-width: 50%;

        button {
          background-color: #006364;
          color: #ffffff;
          font-size: 18px;
          border: 1px solid #ffffff;
          outline: none;
          box-shadow: none;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          width: 100%;
        }
      }
    }

    .feeTierBox {
      display: block;
      position: relative;
      border: 1px solid #3c6d6e;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      padding-right: 80px;

      p {
        font-size: 16px;
        margin: 0 0 3px;
      }

      span {
        display: block;
        font-size: 12px;
      }

      button {
        background-color: #006364;
        color: #ffffff;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        border: none;
        outline: none;
        font-size: 16px;
        padding: 6px 3px;
        min-width: 55px;
        border-radius: 2px;
      }
    }

    .bestForBox {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 20px;

      .bstfrbxInr {
        border: 1px solid #3c6d6e;
        padding: 15px;
        border-radius: 5px;
        margin-top: 10px;
        flex: 0 0 calc(33.33% - 10px);
        max-width: calc(33.33% - 10px);
        margin: 0px 5px 10px;
        position: relative;
        cursor: pointer;

        .checkbox {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 20px;
          height: 20px;
          background-color: #006364;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          display: none;

          svg {
            width: 15px;
            height: auto;
          }
        }

        &.active {
          outline: 2px solid #3c6d6e;

          .checkbox {
            display: flex;
          }
        }

        &:hover {
          outline: 2px solid #111111;
        }
      }

      p {
        font-size: 18px;
        margin: 0 0 3px;
      }

      span {
        display: block;
        font-size: 12px;
      }


    }

    .setPriceRangeBx {
      text-align: center;
      padding: 30px 0px;

      svg {
        stroke: #ffffff;
        margin-bottom: 20px;
      }

      p {
        font-size: 20px;
      }
    }

    .setStatingPrice {
      .infoBx {
        background-color: #004747;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 15px;

        p {
          margin: 0px;
          font-size: 14px;
        }
      }

      .inptBx {
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid #3c6d6e;

        input {
          width: 100%;
          border: none;
          padding: 8px;
          border-radius: 5px;
          font-size: 20px;
          outline: none;
          box-shadow: none;
          background-color: #183a3b;
          color: #ffffff;
        }
      }

      .currentPriceBx {
        margin-bottom: 15px;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid #3c6d6e;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0px;
          font-size: 16px;
        }

        b {
          font-size: 18px;
          margin: 0px 5px;
          font-weight: 500;
        }

        span {
          font-size: 16px;
        }
      }
    }

    .minMaxPrice {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;

      .minmxPrcInr {
        border: 1px solid #3c6d6e;
        padding: 15px;
        border-radius: 5px;
        margin-top: 10px;
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin: 0px 5px 10px;
        text-align: center;

        p {
          font-size: 12px;
        }
      }

      .inptBox {
        display: flex;
        align-items: center;
        margin: 10px 0px;

        button {
          flex: 0 0 30px;
          max-width: 30px;
          background-color: #006364;
          color: #ffffff;
          border-radius: 50px;
          border: none;
          height: 30px;
          font-size: 20px;
        }

        input {
          width: 100%;
          text-align: center;
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
          color: #ffffff;
        }

        .react-numeric-input {
          flex: 0 0 calc(100% - 60px);
          max-width: calc(100% - 60px);

          input {
            width: 100%;
            text-align: center;
            border: none !important;
            outline: none;
            box-shadow: none;
            line-height: 1 !important;
            padding: 0px !important;
            font-size: 22px !important;
            background-color: transparent;
            color: #ffffff;
          }

          b {
            display: none;
          }
        }
      }

      p {
        font-size: 18px;
        margin: 0 0 3px;
      }
    }

    .fullRngBtn {
      width: 100%;
      background-color: #006F76;
      color: #ffffff;
      border: none;
      line-height: 1.2;
      border-radius: 50px;
      padding: 10px;
      margin-top: 5px;

      &:hover {
        background-color: #006364;
      }
    }

    .depositAmuntBx {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      background-color: #006364;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 15px;

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }

      input {
        flex-basis: calc(100% - 140px);
        max-width: calc(100% - 140px);
        margin-right: 15px;
        width: 100%;
        border-radius: 5px;
        padding: 10px 5px;
        font-size: 24px;
        font-weight: 600;
        border: none;
        outline: none;
        background-color: transparent;
        color: #ffffff;

        &::placeholder {
          opacity: 1;
          color: #ffffff;
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .rightBx {
        flex: 0 0 125px;
        max-width: 125px;
        text-align: right;

        .coinbx {
          margin: 0px;
          display: flex;
          align-items: center;
          background-color: #ffffff;
          padding: 4px 8px;
          border-radius: 5px;
          border: none;
          outline: none;
          margin-left: auto;
          font-size: 16px;



          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
            border-radius: 50px;
          }
        }

        .maxBtn {
          border: none;
          margin-top: 4px;
          border-radius: 3px;
          font-size: 14px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .bxBtnMainFoot {
      width: 100%;
      background-color: #012728;
      font-size: 20px;
      border-radius: 5px;
      padding: 12px;
      color: #01ffff;
      border: 1px solid #01ffff;
      transition: all 0.3s ease-in-out;

      &:hover {
        filter: drop-shadow(0px 0px 10px rgba(1, 255, 255, 0.5));
      }
    }
  }

  &.selectTokenMdl {
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.8);

    .modal-dialog {
      max-width: calc(100% - 40px);
      width: 420px;
      margin-left: auto;
      margin-right: auto;

      .modal-content {
        background-color: #006F76;
        padding: 0px;
        font-family: "Poppins", sans-serif;
        border: none;
        box-shadow: 0px 0px 40px 0px #000000;
        color: #000000;
        // height: calc(100% - 10vh);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -6px;
          right: -4px;
          background-image: url("../images/farming-box-left-frame.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-size: contain;
          z-index: 0;
          transform: scaleX(-1);
          width: 54px;
          height: 94px;
          background-position: bottom right;
        }

        &::after {
          content: "";
          position: absolute;
          top: -10px;
          background-image: url("../images/farming-box-right-frame.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-size: contain;
          z-index: 0;
          transform: scaleX(-1);
          left: -5px;
          width: 74px;
          height: 184px;
          background-position: top left;
        }
      }
    }

    .modal-footer {
      text-align: center;
      padding: 0px;


      button {
        color: #ffffff;
        margin: 0 auto;
        padding: 15px;
        font-size: 16px;
        border-radius: 0px 0px 6px 6px;
        width: 100%;
        outline: none;
        border: none;
        background-color: #004848;

        svg {
          margin-right: 6px;
          position: relative;
          top: -2px;
          display: inline-block;
        }
      }
    }

    .inputBox {
      margin: 0 0 20px;
      padding-left: 20px;
      padding-right: 20px;

      input {
        width: 100%;
        font-size: 18px;
        color: #000000;
        padding: 12px;
        line-height: 1;
        border-radius: 10px;
        border: 1px solid #c2c2c2;
        outline: none;
      }
    }

    .modal-body {
      // background-color: #ffffff;
      padding: 0px;
    }

    .modal-header {
      border: none;
      padding-left: 20px;
      padding-right: 20px;
      color: #ffffff;

      .backIcon {
        cursor: pointer;
        display: inline-block;
        margin-right: 10px;

        svg {
          position: relative;
          top: -2px;
        }
      }
    }

    .listViewSel {
      padding: 0px 20px 15px;
      margin: 0px 0px 0px;
      list-style: none;
      display: block;
      border-bottom: 1px solid #005658;

      li {
        background-color: #005e64;
        border: 1px solid #ffffff;
        border-radius: 5px;
        margin-right: 8px;
        padding: 5px 6px;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;

        &.active {
          background-color: #003e42;
          border-color: #01ffff;
        }

        img {
          width: 24px;
          height: 24px;
          border-radius: 50px;
          object-fit: contain;
          margin-right: 6px;
        }
      }
    }

    .gridViewSel {
      padding: 0px 0px 20px;
      margin: 0px;
      list-style: none;
      display: block;

      li {
        background-color: transparent;
        padding: 10px 20px;
        font-size: 16px;
        color: #ffffff;
        line-height: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        transition: all 0.1s ease-in-out;

        &:hover {
          background-color: #005e64;
        }

        .inrBx {
          display: flex;
          align-items: center;
        }

        p {
          margin: 0px;
          font-weight: 400;
          font-size: 16px;

          span {
            display: block;
            font-weight: 400;
            font-size: 12px;
            margin-top: 4px;
          }
        }

        img {
          width: 24px;
          height: 24px;
          border-radius: 50px;
          object-fit: contain;
          margin-right: 12px;
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      li {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: center;
        background-color: #dddddd;
        padding: 10px;
        border-radius: 10px 0px 0px 10px;

        a {
          background-color: transparent;
          cursor: pointer;
          padding: 10px;
          border-radius: 10px;
          color: #000000;
          font-size: 20px;

          &:hover {
            opacity: 0.8;
          }

          &.active {
            background-color: #afafaf;
          }
        }

        &:nth-child(2) {
          border-radius: 0px 10px 10px 0px;
        }
      }
    }

    .tab-content {
      padding: 0px 20px;

      li {
        background-color: transparent;
        padding: 10px 0px;
        font-size: 16px;
        color: #000000;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        transition: all 0.1s ease-in-out;
        border-radius: 10px;

        &:hover {
          background-color: transparent;
        }

        .inrBx {
          display: flex;
          align-items: center;
        }

        .switchOnOff {
          position: relative;
          text-align: right;

          input {
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            top: 0px;
            left: 0px;
            z-index: 1;
            opacity: 0;
          }

          span {
            &::before {
              content: "On";
            }
          }

          input:checked+span::before {
            content: "Off"
          }
        }

        p {
          margin: 0px;
          font-weight: bold;
          font-size: 16px;

          span {
            display: block;
            font-weight: 400;
            font-size: 12px;
            margin-top: 4px;

            svg {
              padding: 0px;
              width: 12px;
              display: inline-block;
              height: auto;
              position: relative;
              top: -1px;
            }

            .btn-group {
              margin: 0px 0px 0px 10px;

              button {
                padding: 0px;
                height: auto;
                margin: 0;
                background: none;
                border: none;
              }
            }

            .dropdown-menu {
              li {
                background-color: transparent !important;
                padding: 5px 15px;

                a {
                  padding: 0px;
                  background-color: transparent !important;
                  color: #000000;

                  &:hover {
                    text-decoration: underline;
                  }
                }

                &:nth-child(1) {
                  padding-top: 5px;
                  padding-bottom: 10px;
                  margin-bottom: 10px;
                  border-bottom: 1px solid #dddddd;

                  a {
                    color: #777777;
                  }
                }
              }
            }
          }
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          object-fit: contain;
          margin-right: 12px;
        }
      }
    }
  }

  &.previewModalBox {
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.8);

    .modal-dialog {
      max-width: calc(100% - 40px);
      width: 420px;
      margin-left: auto;
      margin-right: auto;

      .modal-content {
        background-color: #006F76;
        padding: 0px;
        font-family: "Poppins", sans-serif;
        border: none;
        box-shadow: 0px 0px 40px 0px #000000;
        color: #000000;
        // height: calc(100% - 10vh);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -6px;
          right: -4px;
          background-image: url("../images/farming-box-left-frame.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-size: contain;
          z-index: 0;
          transform: scaleX(-1);
          width: 54px;
          height: 94px;
          background-position: bottom right;
        }

        &::after {
          content: "";
          position: absolute;
          top: -10px;
          background-image: url("../images/farming-box-right-frame.svg");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-size: contain;
          z-index: 0;
          transform: scaleX(-1);
          left: -5px;
          width: 74px;
          height: 184px;
          background-position: top left;
        }
      }
    }

    .modal-footer {
      text-align: center;
      padding: 0px;
    }

    .modal-body {
      padding: 20px;
      position: relative;
      z-index: 1;
    }

    .modal-header {
      border: none;
      padding-left: 20px;
      padding-right: 20px;
      color: #ffffff;
      border-bottom: 1px solid #26848a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: static;
      z-index: 2;

      h5 {
        order: 1;
        position: relative;
        z-index: 2;
      }

      .btn-group {
        order: 0;
        cursor: pointer;
        position: static;

        .dropdown-toggle {
          padding: 0px;
          outline: none;
          box-shadow: none;
          background-color: transparent;
          border: none;
          position: static;

          svg {
            stroke: #ffffff;
          }
        }

        .settingDownToggle {
          min-width: 256px;
          top: 55px;
          left: 20px;
          position: absolute;
          border-radius: 5px;
          border: 1px solid #009398;
          padding: 0px;
          box-shadow: 0px 20px 50px -30px #000000;
          background-color: #001f20;
          color: #ffffff;
          transition: all 0.3s ease-in;
          z-index: 3;
          transform: scale(0);
          transform-origin: top left;
          cursor: default;
        }

        &.show {
          .dropdown-toggle {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.769);
              z-index: -1;
            }
          }

          .settingDownToggle {
            transform: scale(1);
          }
        }
      }

      svg {
        width: 25px;
        height: auto;

      }

      .btn-close {
        position: relative;
        margin: 0px;
        order: 2;
        filter: invert(1);
        opacity: 1;
        z-index: 2;
      }

      .settingDownToggle {
        .css-bbq5bh {
          svg {
            display: inline-block;
            width: 18px;
            height: auto;

            &+div {
              min-width: max-content;

              &>div {
                padding: 4px 6px;
                font-size: 14px;
                border-radius: 2px;
              }
            }
          }
        }

        h3 {
          font-size: 18px;
          margin: 0px 0px 10px;
          font-weight: 500;
          color: #01b1b1;
        }

        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;

          li {
            margin: 0px 0px 20px;
          }

          p {
            margin: 0px 0px 10px;
            font-size: 16px;

            svg {
              fill: #ffffff;
              margin-left: 10px;
            }
          }
        }

        .rowBox {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.toleranceBox {
            button {
              flex: 1 1;
              border: none;
              margin-right: 10px;
              border-radius: 5px;
              line-height: 1;
              padding: 6px 8px;
              background-color: #01ffff;
              color: #000000;
            }

            .inputBox {
              position: relative;
              flex: auto;
              max-width: 220px;

              &::after {
                content: "%";
                position: absolute;
                top: 50%;
                right: 7px;
                transform: translateY(-50%);
                color: #000000;
                font-weight: bold;
              }

              input {
                border: none;
                padding: 0 4px;
                padding-right: 25px;
                width: 100%;
                text-align: right;
                border-radius: 2px;
                outline: none;
                box-shadow: none;
                line-height: 1.66;
                color: #000000;
              }
            }
          }

          &.deadlineBox {
            justify-content: flex-start;

            .inputBox {
              position: relative;
              flex: 1 1;
              max-width: 80px;
              margin-right: 10px;

              input {
                border: none;
                padding: 0 4px;
                padding-right: 15px;
                width: 100%;
                text-align: right;
                border-radius: 2px;
                outline: none;
                box-shadow: none;
                line-height: 1.66;
                color: #000000;
              }
            }
          }

          &.rowBxinte {
            .form-check {
              padding: 0px !important;

              input {
                filter: brightness(100%);
                box-shadow: none !important;
                border: 1px solid transparent !important;
                background-color: #006f76;
                outline: none !important;
                margin-left: 0 !important;

                &:checked {
                  border: 1px solid #ffffff !important;
                }
              }
            }
          }
        }
      }
    }

    .topheadTtl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h2 {
        margin: 0px;
        display: flex;
        align-items: center;
      }

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        border-radius: 50px;
        border: 1px solid #2b8592;
        position: relative;

        &+img {
          left: -8px;
        }
      }

      strong {
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;
        display: inline-block;
      }

      span {
        color: #01ffff;
        font-size: 16px;

        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: #01ffff;
          display: inline-block;
          border-radius: 50px;
          margin-left: 5px;
        }
      }
    }

    .feeTierTable {
      padding: 20px;
      // border: 1px solid #00979c;
      border-radius: 5px;
      background-color: #003132;

      .rowBx {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        strong {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #ffffff;
          font-weight: 400;

          img {
            width: 28px;
            height: 28px;
            object-fit: contain;
            border-radius: 50px;
            border: 1px solid #003132;
            position: relative;
            margin-right: 8px;

          }
        }

        span {
          color: #ffffff;
          font-size: 18px;
        }

        &:nth-last-child(1) {
          border-top: 1px solid #00979c;
          padding: 10px 0px 0px;
          margin: 15px 0px 0px;

          strong {
            font-size: 20px;
          }
        }
      }
    }

    .selectedRangeBox {
      margin-top: 15px;

      ul {
        padding: 0px;
        margin: 0px 0 15px;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          color: #ffffff;
          font-size: 18px;
          margin: 0px;
        }

        .tabmenu {
          display: flex;
          align-items: center;
          border: 1px solid #003132;
          color: #ffffff;
          font-size: 16px;
          border-radius: 5px;
          overflow: hidden;

          li {
            padding: 5px;
            cursor: pointer;
            outline: none;
            box-shadow: none;
            border: none;

            &.react-tabs__tab--selected {
              background-color: #003132;
            }
          }
        }
      }

      .react-tabs__tab-panel {
        .row {
          .infoBox {
            background-color: #003132;
            border-radius: 5px;
            text-align: center;
            color: #ffffff;
            padding: 15px;

            strong {
              font-weight: 400;
              line-height: 1;
              font-size: 16px;
              display: block;
              margin: 0 0 3px;
              color: #4ab4bb;
            }

            h3 {
              font-size: 20px;
              margin: 0 0 3px;
            }

            p {
              font-size: 14px;
              margin: 10px 0 0;
              line-height: 1.1;
              opacity: 0.6;
            }
          }
        }

        .addBtn {
          width: 100%;
          background-color: #003132;
          color: #ffffff;
          line-height: 1;
          border-radius: 50px;
          padding: 15px 10px;
          font-weight: 500;
          font-size: 19px;
          border: 1px solid #00979c;

          &:hover {
            background-color: #006364;
          }
        }
      }

      &.removeLiqBx {
        h2 {
          color: #ffffff;
          font-size: 22px;
        }
      }
    }
  }

  &.stakeModalPopup {
    input[type="number"] {
      padding: 10px;
      border-radius: 5px;
      margin-left: 10px;
      background-color: #004a4e;
      color: #ffffff;
      outline: none;
      box-shadow: none;
    }

    .modal-dialog .modal-content {
      color: #ffffff;

    }

    .stakeModalBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px auto 25px;
      max-width: max-content;

      button {
        color: aqua;
        border: none;
        font-size: 18px;
        padding: 10px;
        position: relative;
        min-width: 115px;
        margin: 0 10px;
        line-height: 1.1;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 50px;
          background-image: url('../images/poolbox/poolboxtopline.svg');
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: top center;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50px;
          background-image: url('../images/poolbox/poolboxbottomline.svg');
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: bottom center;
        }
      }
    }

    .tokenDiv {
      font-size: 16px;

      span {
        margin-left: 6px;
      }
    }

    hr {
      margin: 10px 0px;
    }

    p {
      font-size: 16px;

      b {
        color: #08fdff;
        font-weight: 500;
      }
    }
  }
}

.yourPositionBox {
  padding: 0px;
  border: 1px solid #006f76;
  border-radius: 5px;
  overflow: hidden;

  .headTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #006f76;
    background-color: #002324;

    h2 {
      margin: 0px;
      font-size: 18px;
    }

    a {
      color: #ffffff;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      font-size: 15px;
    }
  }

  .rowBoxItems {
    border-bottom: 1px solid #1f4747;

    a {
      padding: 20px;
      display: block;
      text-decoration: none;
    }
  }

  .topheadTtl {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-last-child(1) {
      border: none;
    }

    h2 {
      margin: 0px;
      display: flex;
      align-items: center;
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      border-radius: 50px;
      border: 1px solid #2b8592;
      position: relative;

      &+img {
        left: -8px;
      }
    }

    strong {
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      display: inline-block;
    }

    span {
      color: #01ffff;
      font-size: 16px;

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #01ffff;
        display: inline-block;
        border-radius: 50px;
        margin-left: 5px;
      }
    }
  }

  p {
    color: #ffffff;
    font-size: 14px;
    margin: 5px 0px 0px;

    span {
      display: inline-block;
      color: #00FFFF;
      margin: 0 8px;
    }

    svg {
      fill: #00FFFF;
      width: 25px;
      height: auto;
      display: inline-block;
      margin: 0 8px;
    }
  }
}

.nftLiquidity {
  padding: 20px 0px;

  .topheadTtl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    h2 {
      margin: 0px;
      display: flex;
      align-items: center;
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      border-radius: 50px;
      position: relative;

      &+img {
        left: -8px;
      }
    }

    strong {
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      display: inline-block;
    }

    b {
      background-color: #005658;
      display: inline-block;
      padding: 5px;
      font-size: 14px;
      border-radius: 3px;
      margin: 0px 10px;
    }

    span {
      color: #01ffff;
      font-size: 16px;

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #01ffff;
        display: inline-block;
        border-radius: 50px;
        margin-left: 5px;
      }
    }

    .btnGrp {
      display: flex;
      align-items: center;

      button {
        background-color: #003a3b;
        padding: 4px 6px;
        border-radius: 2px;
        color: #08d5c6;

        &:nth-last-child(1) {
          margin-left: 10px;
          background-color: #08d5c6;
          color: #000000;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .innerbox {
    background-color: #003a3b;
    padding: 30px 20px;
    height: 100%;
    border-radius: 5px;

    .innerboxImg {
      border: 1px solid #006e74;
      height: 100%;
      min-height: 200px;
      max-width: 500px;
      margin: 0 auto;
      border-radius: 5px;
    }
  }

  .liquidityBox {
    margin-bottom: 20px;

    h4 {
      font-size: 20px;
      font-weight: 400;
    }

    h2 {
      font-size: 36px;
      font-weight: 500;
    }
  }

  .liquidityTable {
    border: 1px solid #006e74;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;

    &:nth-last-child(1) {
      margin-bottom: 0px;
    }

    .rowBoxTbl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }

      .imgBx {
        display: flex;
        align-items: center;
        margin: 0px;

        img {
          width: 25px;
          height: 25%;
          margin-right: 8px;
          border-radius: 50px;
          object-fit: contain;
          height: auto;
        }

        strong {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .rightBx {
        strong {
          font-size: 16px;
          font-weight: 400;
        }

        span {
          font-size: 14px;
          background-color: #006e74;
          padding: 3px 6px;
          display: inline-block;
          margin-left: 8px;
          border-radius: 2px;
          line-height: 1;
        }
      }
    }
  }

  .priceRangeBox {
    margin-top: 30px;

    ul {
      padding: 0px;
      margin: 0px 0 15px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        color: #ffffff;
        font-size: 18px;
        margin: 0px;

        span {
          color: #01ffff;
          display: inline-block;
          margin-left: 5px;
          font-size: 14px;

          &::after {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #01ffff;
            display: inline-block;
            border-radius: 50px;
            margin-left: 5px;
          }
        }
      }

      .tabmenu {
        display: flex;
        align-items: center;
        border: 1px solid #003132;
        color: #ffffff;
        font-size: 16px;
        border-radius: 5px;
        overflow: hidden;

        li {
          padding: 5px;
          cursor: pointer;
          outline: none;
          box-shadow: none;
          border: none;
          font-size: 14px;
          font-weight: 400;

          &.react-tabs__tab--selected {
            background-color: #003132;
          }
        }
      }
    }

    .react-tabs__tab-panel {
      .row {
        .infoBox {
          background-color: #003132;
          border-radius: 5px;
          text-align: center;
          color: #ffffff;
          padding: 15px;

          strong {
            font-weight: 400;
            line-height: 1;
            font-size: 16px;
            display: block;
            margin: 0 0 3px;
            color: #4ab4bb;
          }

          h3 {
            font-size: 20px;
            margin: 0 0 3px;
          }

          p {
            font-size: 14px;
            margin: 10px 0 0;
            line-height: 1.1;
            opacity: 0.6;
          }
        }
      }

      .addBtn {
        width: 100%;
        background-color: #003132;
        color: #ffffff;
        line-height: 1;
        border-radius: 50px;
        padding: 15px 10px;
        font-weight: 500;
        font-size: 19px;
        border: 1px solid #00979c;

        &:hover {
          background-color: #006364;
        }
      }
    }
  }
}

.removeLiqBx {
  .enterPercentBtn {
    width: 100%;
    background-color: #003132;
    color: #ffffff;
    line-height: 1;
    border-radius: 50px;
    padding: 15px 10px;
    font-weight: 500;
    font-size: 19px;
    border: 1px solid #00979c;

    &:hover {
      background-color: #006364;
    }
  }

  .amountBox {
    padding: 20px;
    border-radius: 5px;
    background-color: #003132;
    color: #ffffff;
    margin-bottom: 20px;

    h2 {
      margin: 0 0 10px;
      color: #ffffff;
      font-size: 22px;
    }

    .countBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      strong {
        font-size: 32px;
        font-weight: 400;
      }

      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-left: 10px;
          background-color: #006f76;
          padding: 4px 10px;
          font-size: 15px;
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }

    .form-range {
      &::-webkit-slider-thumb {
        background-color: #006f76;
        border-radius: 1.5rem;
        height: 1.5rem;
        margin-top: -.50rem;
        width: 1.5rem;
        outline: 2px solid #ffffff !important;
        box-shadow: none !important;
      }
    }
  }

  .topheadTtl {
    margin-bottom: 10px !important;

    &:nth-last-child(1) {
      margin-bottom: 0px !important;
    }

    span {
      &::after {
        display: none !important;
      }
    }

    img {
      width: 22px !important;
      height: 22px !important;
      margin-left: 7px;
    }

    .form-check {
      padding: 0px !important;

      input {
        filter: brightness(100%);
        box-shadow: none !important;
        border: 1px solid transparent !important;
        background-color: #006f76;
        outline: none !important;
        margin-left: 0 !important;

        &:checked {
          border: 1px solid #ffffff !important;
        }
      }
    }
  }
}

.returnSwapbox {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;

  h2 {
    color: #02ffff;
    font-size: 30px;
    margin-bottom: 5px;
  }

  p {
    color: #ffffff;
    opacity: 0.6;
    font-size: 14px;
    font-weight: 400;
  }

  .btnBox {
    background: url(../images/farming-harvest-btn.svg);
    padding: 8px;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-size: 100% 100%;
    position: relative;
    min-width: 110px;
    text-align: center;
  }

  button {
    font-size: 14px;
    display: block !important;
    width: 100%;
  }

  .returnInrBox {
    border: 1px solid #006365;
    padding: 15px;
    border-radius: 5px;
    text-align: left;

    p {
      opacity: 1;
      color: #bababa;

      strong {
        display: block;
        color: #ffffff;
        margin-top: 3px;
      }
    }
  }
}

/* media start */
@media (max-width:1680px) {
  .dashboard {
    padding: 4vw;

    .oc_row {
      .oc_col6 {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 25px;

        .boxTopLeft {
          max-width: 550px;

          .boxline {
            margin-top: 30px;
            max-width: 415px;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }

        .boxTopRight {
          max-width: 550px;

          .boxline {
            margin-top: 65px;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .oc_row {
      .circleBox {
        .infobox {
          .Typewriter {
            flex-wrap: wrap;
            max-width: 500px;
          }
        }
      }
    }

    .oc_row {
      .boxBottomLeft {
        max-width: 350px;
      }

      .boxBottomRight {
        max-width: 300px;
      }
    }

  }

  .swap {
    .swap-container {
      .button-1 {
        max-width: 300px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .swap-main-box {
        .input-boxes {
          .token-box-1 {
            position: relative;
            width: 100%;
            margin: 0 auto;
            display: block;

            svg {
              width: 100%;
              height: 100%;
            }

            label {
              margin-bottom: 45px;
            }
          }

          .token-box-2 {
            max-width: 80px;
            position: relative;
            width: 100%;
            display: block;
          }

          .token-box-3 {
            position: relative;
            width: 100%;
            margin: 0 auto;
            display: block;

            svg {
              position: relative;
              z-index: -1;
              width: 100%;
              height: 100%;
            }

            .main-box {
              width: 450px;
            }

            label {
              margin-bottom: 35px;
            }
          }

          .token-button {
            margin-top: 40px;

            span {
              font-size: 25px;
            }

            .bg-button {
              max-width: 420px;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .nfts {
    .nfts-container {
      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 22px;
            }

            &::after {
              top: -18px;
              bottom: 0;
              width: 360px;
            }

          }
        }
      }

      .nfts-outer-button-border {
        .nfts-inner-button-border {
          .nfts-button-grp {
            ul {
              li {
                padding: 10px 24px;
                font-size: 16px;
              }
            }
          }
        }
      }

      .nfts-content-area {
        .nfts-box-1 {
          .nfts-border-1 {

            .nfts-input-box {

              .nfts-sub-input-box {
                height: 90px;
              }

              .nfts-volume-box {
                display: flex;
                justify-content: space-between;
                margin: 0 10px 0 10px;
                align-items: center;

                .volume-box-1 {
                  width: 65px;
                  height: 100%;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }

                .volume-box-2 {
                  span {
                    display: block;
                    text-align: end;
                    color: #01FFFF;
                  }

                  .span-1 {
                    font-size: 29px;
                    margin-bottom: 7px;
                  }

                  .span-2 {
                    font-size: 17px;
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  .gridlistview {
    .farming-container {
      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 22px;
            }

            &::after {
              top: -18px;
              bottom: 0;
            }
          }
        }
      }

      .farming-button-area {
        .empty-area {
          width: 400px;
        }

        .flex-btn-group {
          .view-btn-group {
            .list-btn {
              max-width: 55px;

              button {
                width: 100%;
              }
            }

            .gird-btn {
              max-width: 55px;

              button {
                width: 100%;
              }
            }
          }

          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                ul {
                  li {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }

      .farming-outer-button-border-1 {

        .farming-inner-button-border {
          .farming-button-grp {
            ul {
              li {
                padding: 10px 24px;
                font-size: 16px;

                .dropdown-item {
                  padding: 10px;
                }
              }
            }


            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        .farming-content-area {
          .farming-box-1 {
            max-height: 100px;
            width: 100%;

            .frame-sub-box-1 {
              font-size: 22px;
            }

            .frame-sub-box-2 {
              padding-inline: 14px;
              display: flex;
              width: 100%;
              flex: 0 0 70%;
              flex-wrap: wrap;
              justify-content: space-between;

              .frame-input-sub-1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 0 0 55%;
                margin-top: 14px;

                .span-area-1 {
                  font-size: 20px;
                }

                .span-area-2 {
                  font-size: 20px;
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 40px;

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 20px;
                flex: 0 0 50%;

                .span-area-3 {
                  p {
                    font-size: 20px;
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 50%;

                .span-area-5 {
                  p {
                    font-size: 14px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 20px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              display: table-row;
              width: 100%;
              flex: 0 0 100%;

              button {
                background: transparent;
                color: aqua;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                max-width: 400px;

                span {
                  font-size: 22px;
                }

                .bg-button {
                  position: relative;
                  transition: all 0.5s;
                  mix-blend-mode: hard-light;
                  z-index: 99;
                  max-width: 506px;
                }

              }

            }

          }

          .farming-box-1.height-slider {
            max-height: 400px;
          }
        }
      }
    }
  }

  .nfts .nfts-container .nfts-content-area {
    margin: 0px auto 0;
  }
}

@media (max-width:1400px) {
  .dashboard {

    .oc_row {
      .oc_col6 {

        .boxTopLeft {
          .chartData {
            img {
              padding-left: 0;
            }

            span {
              right: 8px;
            }
          }

          .boxline {
            margin-top: 30px;
            max-width: 334px;
          }
        }

        .boxTopRight {
          .svgCirBox {
            width: auto;

            .mainbox {
              max-width: 92px;
              width: 100%;
              height: auto;
              max-height: 92px;

              img {
                width: 100%;
                height: 100%;
              }

              svg.circular-chart {
                width: 100%;
                height: 100%;
                max-width: 92px;
              }
            }

            span {
              font-size: 16px;
            }
          }
        }
      }
    }

    .oc_row {
      .circleBox {
        margin-bottom: 3vw;
        max-height: 285px;
      }
    }

    .oc_row {
      .boxBottomLeft {
        max-width: 312px;

        .progressbox {
          padding: 15px 42px;

          .progressboxinr {
            p {
              font-size: 15px;
            }

            .progress {
              height: 21px;

            }
          }
        }

        .oc_row {
          flex-wrap: nowrap;

          .innerbox {
            height: 20vw;
            max-height: 115px;
            min-height: 100px;
            margin-bottom: 3.5vw;
            padding-inline: 5px;

            h2 {
              font-size: 18px;
            }
          }
        }

      }

      .boxBottomRight {
        max-width: 310px;

        .progressbox {
          padding: 15px 40px;
          width: 310px;
          height: 320px;

          .progressboxinr {
            .progress {
              height: 21px;

            }
          }
        }

      }

      .boxBottomRight::after {
        bottom: -11px;
        font-size: 21px;
      }
    }

  }

  .swap {
    .swap-container {
      .button-1 {
        max-width: 250px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .swap-main-box {
        .input-boxes {
          .token-box-1 {
            position: relative;
            width: 100%;
            margin: 0 auto;
            max-width: 500px;
            display: block;

            svg {
              width: 100%;
              height: 100%;
            }

            .main-box {
              width: 400px;
            }

            label {
              margin-bottom: 35px;
            }
          }

          .token-box-2 {
            max-width: 80px;
          }

          .token-box-3 {
            width: 100%;
            max-width: 500px;
            margin-inline: auto;

            svg {
              position: relative;
              z-index: -1;
              width: 100%;
              height: 100%;
            }

            .main-box {
              width: 400px;
            }

            label {
              margin-bottom: 35px;
            }

          }

          .swapNetwork {
            max-width: 500px;
          }


          .token-button {
            margin-top: 40px;

            span {
              font-size: 20px;
            }

            .bg-button {
              max-width: 350px;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .nfts {
    .nfts-container {
      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 19px;
            }

            &::after {
              top: -21px;
              bottom: 0;
              width: 100%;
            }

          }
        }
      }

      .nfts-button {
        .button-1 {
          max-width: 250px;
        }
      }

      .nfts-outer-button-border {
        .nfts-inner-button-border {
          .nfts-button-grp {
            ul {
              li {
                padding: 10px 17px;
                font-size: 15px;
              }
            }
          }
        }
      }

      .nfts-content-area {
        .nfts-box-1 {
          .nfts-border-1 {

            .nfts-input-box {

              .nfts-sub-input-box {
                height: 99px;
              }

              .nfts-volume-box {
                display: flex;
                justify-content: space-between;
                margin: 0 10px 0 10px;
                align-items: center;

                .volume-box-1 {
                  width: 65px;
                  height: 100%;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }

                .volume-box-2 {
                  span {
                    display: block;
                    text-align: end;
                    color: #01FFFF;
                  }

                  .span-1 {
                    font-size: 29px;
                    margin-bottom: 7px;
                  }

                  .span-2 {
                    font-size: 17px;
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  .gridlistview {
    .farming-container {
      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 19px;
            }

            &::after {
              top: -21px;
              bottom: 0;
            }

          }
        }

        .nfts-button {
          .button-1 {
            max-width: 250px;
          }
        }
      }

      .farming-button-area {
        .empty-area {
          width: 242px;
        }

        .flex-btn-group {
          .view-btn-group {
            width: 120px;

            .list-btn {
              max-width: 55px;
              margin-right: 0px;

              button {
                width: 100%;
              }
            }

            .gird-btn {
              max-width: 55px;
              margin-right: 0px;

              button {
                width: 100%;
              }
            }
          }

          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                ul {
                  li {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }

      .farming-outer-button-border-1 {

        .farming-inner-button-border {
          .farming-button-grp {
            ul {
              li {
                padding: 10px 24px;
                font-size: 14px;

                .dropdown-item {
                  padding: 10px;
                }
              }
            }


            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        .farming-content-area {
          .farming-box-1 {
            max-height: 100px;
            width: 100%;

            .frame-sub-box-1 {
              flex: 0 0 30%;
            }

            .frame-sub-box-2 {
              padding-inline: 14px;
              display: flex;
              width: 100%;
              flex: 0 0 70%;
              flex-wrap: wrap;
              justify-content: space-between;

              .frame-input-sub-1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 0 0 70%;
                margin-top: 14px;

                .span-area-1 {
                  font-size: 20px;
                }

                .span-area-2 {
                  font-size: 20px;
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 40px;

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 20px;
                flex: 0 0 50%;

                .span-area-3 {
                  p {
                    font-size: 20px;
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 50%;

                .span-area-5 {
                  p {
                    font-size: 14px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 20px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              display: table-row;
              width: 100%;
              flex: 0 0 100%;

              button {
                background: transparent;
                color: aqua;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                max-width: 400px;

                span {
                  font-size: 22px;
                }

                .bg-button {
                  position: relative;
                  transition: all 0.5s;
                  mix-blend-mode: hard-light;
                  z-index: 99;
                  max-width: 506px;
                }

              }

            }

          }

        }
      }
    }
  }

  .gridlistview:not(.listviewmode) .farming-container .farming-content-area .farming-box-1 {
    flex-basis: calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .gridlistview .farming-container .search-box-content .empty-space,
  .nfts .nfts-container .search-box-content .empty-space {
    display: none;
  }

  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border {
    width: calc(100% - 120px);
  }
}

@media (max-width:1200px) {
  .dashboard {
    .oc_row {
      .oc_col6 {

        .boxTopLeft {
          .chartData {
            span {
              right: -7px;
            }
          }

          .boxline {
            max-width: 306px;
          }
        }

        .boxTopRight {
          .oc_row {
            .chartData {
              span {
                left: 16px;
                font-size: 19px;
              }
            }
          }

        }
      }
    }

    .oc_row {
      .boxBottomRight {
        max-width: 300px;

        .border_line {
          img {
            left: -120px;
            max-width: 370px;
          }

          .progressbox {
            padding: 15px 43px;
            width: 304px;
            height: 290px;

            .progressboxinr {
              margin-bottom: 9px;

              .progress {
                height: 18px;

              }
            }
          }
        }

      }

      .boxBottomRight::after {
        bottom: 0px;
      }
    }

  }

  .swap {
    .swap-container {
      .button-1 {
        max-width: 210px;

        span {
          font-size: 15px;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .swap-main-box {
        .input-boxes {
          .token-box-1 {
            position: relative;
            width: 100%;
            margin: 0 auto;
            max-width: 470px;
            display: block;

            svg {
              width: 100%;
              height: 100%;
            }

            .main-box {
              width: 360px;
            }

            label {
              margin-bottom: 30px;
              font-size: 22px;
            }

            input[type=text] {
              font-size: 22px;
            }

            .bg-logo {
              width: 33px;
              height: 33px;
            }
          }



          .token-box-2 {
            max-width: 80px;

            svg {
              width: 60px;
            }
          }

          .token-box-3 {
            width: 100%;
            max-width: 470px;
            margin-inline: auto;

            svg {
              position: relative;
              z-index: -1;
              width: 100%;
              height: 100%;
            }

            .main-box {
              width: 360px;
            }

            label {
              margin-bottom: 30px;
            }

            input[type=text] {
              font-size: 22px;
            }

            .bg-logo {
              width: 33px;
              height: 33px;
            }

          }

          .token-button {
            margin-top: 40px;

            span {
              font-size: 20px;
            }

            .bg-button {
              max-width: 350px;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .nfts {
    .nfts-container {
      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 18px;
            }
          }
        }
      }

      .nfts-button {
        .button-1 {
          max-width: 210px;

          span {
            font-size: 15px;
          }
        }
      }

      .nfts-outer-button-border {
        .nfts-inner-button-border {
          .nfts-button-grp {
            ul {
              li {
                padding: 10px 14px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .gridlistview {
    .farming-container {
      .scroll-border {
        .scroll {
          .farming-content-area {
            .farming-box-1 {
              &::after {
                left: -4px;
                width: 55px;
                height: 135px;
              }

              .frame-sub-box-1 {
                font-size: 20px;
              }

              .frame-sub-box-2 {
                padding-inline: 10px;

                .frame-input-sub-1 {

                  .span-area-1 {
                    font-size: 16px;
                  }

                  .span-area-2 {
                    font-size: 15px;
                  }
                }

                .frame-input-sub-2 {
                  margin-top: 25px;

                  .span-area-3 {
                    p {
                      font-size: 17px;

                      .span-number {
                        font-size: 16px;
                      }
                    }
                  }

                  .span-area-4 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }

                .frame-input-sub-3 {
                  margin-top: 25px;

                  .span-area-5 {
                    p {
                      font-size: 12px;

                      .span-letter {
                        font-size: 19px;
                        margin-block: 7px;
                      }
                    }
                  }

                  .span-area-6 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }
              }

              .frame-sub-box-3 {
                margin-block: 20px;

                button {
                  span {
                    font-size: 14px;
                  }

                  .bg-button {
                    max-width: 210px;
                  }
                }
              }
            }
          }
        }
      }

      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 18px;
            }
          }
        }

        .nfts-button {
          .button-1 {
            max-width: 210px;

            span {
              font-size: 15px;
            }
          }
        }
      }

      .farming-button-area {
        justify-content: space-between;

        .flex-btn-group {
          .view-btn-group {
            .list-btn {
              max-width: 50px;

              button {
                width: 100%;
              }
            }

            .gird-btn {
              max-width: 50px;

              button {
                width: 100%;
              }
            }
          }

          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                button {
                  padding: 8px 19px;
                }

                ul {
                  li {
                    padding: 10px 14px;
                    font-size: 14px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .farming-outer-button-border-1 {

        .farming-inner-button-border {
          .farming-button-grp {
            ul {
              li {
                padding: 10px 16px;
                font-size: 14px;

                .dropdown-item {
                  padding: 10px;
                }
              }
            }

            button {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        .farming-content-area {
          .farming-box-1 {
            max-height: 84px;
            width: 100%;

            .frame-sub-box-1 {
              flex: 0 0 30%;
              font-size: 17px;
            }

            .frame-sub-box-2 {
              padding-inline: 10px;
              flex: 0 0 70%;

              .frame-input-sub-1 {
                flex: 0 0 73%;
                margin-top: 10px;

                .span-area-1 {
                  font-size: 16px;
                }

                .span-area-2 {
                  font-size: 16px;
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 0px;

                button {
                  max-width: 70px;
                  height: 100%;
                  display: block;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 0px;
                flex: 0 0 50%;

                .span-area-3 {
                  p {
                    font-size: 14px;

                    .span-number {
                      font-size: 14px;
                    }
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 50%;

                .span-area-5 {
                  p {
                    font-size: 12px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 16px;
                      margin-block: 4px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              button {
                max-width: 330px;

                span {
                  font-size: 18px;
                }
              }

            }

          }

        }
      }
    }
  }

  .slideBoxMain .slideMainCir {
    flex: 0 0 500px;
    max-width: 500px;
    margin-left: -170px;
  }

  .slideBoxMain .slideMainCir svg {
    max-width: 202px;
    top: calc(50% + 2px);
    left: calc(50% - 58px);
  }

  .slideBoxMain .slideMainPages {
    flex-basis: calc(100% - 350px);
    max-width: calc(100% - 350px);
    padding-right: 20px;
  }

  .slideBoxMain .slideMainCir .slideMainCirInner .swapLogo {
    top: calc(50% + 5px);
    left: calc(50% - 54px);
    width: 60px;
  }

  .middelBox .oc_col12 {
    width: 100%;
    margin-bottom: 30px;
  }

  .circleBox .infobox .Typewriter {
    font-size: 2vw;
  }

  .circleBox {
    max-width: 100vw;
  }

  .dashboard .oc_row .circleBox .infobox .Typewriter {
    max-width: 100%;
  }

  .swap .swap-container button img {
    width: 20px;
  }
}

@media (max-width:1024px) {
  .gridlistview {
    .farming-container {
      .scroll-border {
        .scroll {
          .farming-content-area {
            .farming-box-1 {
              &::after {
                left: -4px;
                width: 55px;
                height: 135px;
              }

              .frame-sub-box-1 {
                font-size: 20px;
              }

              .frame-sub-box-2 {
                padding-inline: 10px;

                .frame-input-sub-1 {

                  .span-area-1 {
                    font-size: 16px;
                  }

                  .span-area-2 {
                    font-size: 15px;
                  }
                }

                .frame-input-sub-2 {
                  margin-top: 25px;

                  .span-area-3 {
                    p {
                      font-size: 17px;

                      .span-number {
                        font-size: 16px;
                      }
                    }
                  }

                  .span-area-4 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }

                .frame-input-sub-3 {
                  margin-top: 25px;

                  .span-area-5 {
                    p {
                      font-size: 12px;

                      .span-letter {
                        font-size: 19px;
                        margin-block: 7px;
                      }
                    }
                  }

                  .span-area-6 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }
              }

              .frame-sub-box-3 {
                margin-block: 20px;

                button {
                  span {
                    font-size: 14px;
                  }

                  .bg-button {
                    max-width: 210px;
                  }
                }
              }
            }
          }
        }
      }

      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 18px;
            }
          }
        }

        .nfts-button {
          .button-1 {
            max-width: 210px;

            span {
              font-size: 15px;
            }
          }
        }
      }

      .farming-button-area {
        justify-content: space-between;

        .flex-btn-group {
          .view-btn-group {
            .list-btn {
              max-width: 50px;

              button {
                width: 100%;
              }
            }

            .gird-btn {
              max-width: 50px;

              button {
                width: 100%;
              }
            }
          }

          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                button {
                  padding: 8px 19px;
                }

                ul {
                  li {
                    padding: 10px 14px;
                    font-size: 14px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .farming-outer-button-border-1 {

        .farming-inner-button-border {
          .farming-button-grp {
            ul {
              li {
                padding: 10px 16px;
                font-size: 14px;

                .dropdown-item {
                  padding: 10px;
                }
              }
            }

            button {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        .farming-content-area {
          .farming-box-1 {
            max-height: 84px;
            width: 100%;

            .frame-sub-box-1 {
              flex: 0 0 30%;
              font-size: 17px;
            }

            .frame-sub-box-2 {
              padding-inline: 10px;
              flex: 0 0 70%;

              .frame-input-sub-1 {
                flex: 0 0 75%;
                margin-top: 10px;

                .span-area-1 {
                  font-size: 16px;
                }

                .span-area-2 {
                  font-size: 16px;
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 0px;

                button {
                  max-width: 70px;
                  height: 100%;
                  display: block;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 0px;
                flex: 0 0 50%;

                .span-area-3 {
                  p {
                    font-size: 14px;

                    .span-number {
                      font-size: 14px;
                    }
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 50%;

                .span-area-5 {
                  p {
                    font-size: 12px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 16px;
                      margin-block: 4px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 12px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              button {
                max-width: 330px;

                span {
                  font-size: 18px;
                }
              }

            }

          }

        }
      }
    }
  }
}

@media (max-width:992px) {
  .dashboard {
    .oc_row {
      .oc_col6 {
        padding: 15px;

        .boxTopLeft {
          max-width: 380px;

          .chartBox {
            flex: 0 0 100%;
            max-width: 70%;
          }

          .chartData {
            flex: 0 0 100%;
            max-width: 90%;

            span {
              right: 0px;
            }
          }

          .boxline {
            display: none;

          }
        }

        .boxTopRight {
          max-width: 380px;

          .oc_row {
            justify-content: end;

            .chartData {
              width: 322px;
              position: relative;
              flex: 0 0 75%;
              max-width: 75%;
              display: none;
              height: 133px;

              img {
                width: 100%;
                height: 100%;
                position: relative;
                left: -160px;
              }

              span {
                display: none;
              }
            }

            .progrfour {
              flex: 0 0 60%;
              max-width: 59%;
            }

            .progressCirclebox {
              margin-bottom: 6px;
              padding: 0px 8px;
            }
          }

          .boxline {
            margin-top: 85px;
          }

        }

      }
    }

    .oc_row {
      .circleBox {
        max-height: 330px;
        margin-top: -9vw;

        .infobox {
          .Typewriter {
            font-size: 3vw;
            // font-size: 2.3vw;
            margin-top: 15px;
          }
        }
      }
    }

    .oc_row {
      .oc_col12 {
        display: flex;
      }

      .oc_mobile {
        .innerbox {
          max-height: 91px;
          min-height: 96px;
          margin-block: 6.5vw;
        }
      }

      .boxBottomLeft {
        max-width: 350px;
        display: flex;
        flex-direction: column-reverse;

        .progressbox {
          padding: 15px 0px;
          margin-bottom: 0px;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }

        }

        .oc_row {
          display: none;
        }
      }

      .boxBottomRight {
        max-width: 350px;
        align-items: flex-end;

        .border_line {

          &::before {
            display: none;
          }

          img {
            display: none;
          }

          .progressbox {
            padding: 15px 0px;
            margin-bottom: 0px;
            width: 355px;

            &::before {
              display: none;
            }

            &::after {
              display: none;
            }

            .progressboxinr {
              margin-bottom: 12px;

              p {
                text-align: end;
              }

              .progress {
                height: 18px;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                span {
                  margin-right: 10px;
                }
              }
            }
          }

        }

      }

      .boxBottomRight::after {
        bottom: 0px;
        display: none;
      }
    }
  }

  .swap {
    position: relative;

    .swap-container {
      .button-1 {
        margin: 0 auto 30px;
      }

      .swap-main-box {
        .input-boxes {
          .token-box-1 {
            max-width: 450px;

            .main-box {
              width: 345px;
            }

            label {
              margin-bottom: 20px;
              font-size: 19px;
            }

            input[type=text] {
              font-size: 19px;
            }

            .token-balance {
              .token-currency {
                .bg-logo {
                  width: 23px;
                  height: 23px;
                  margin-inline-end: 12px;
                }
              }
            }
          }

          .token-box-2 {
            max-width: 60px;
            margin: 0 auto;
          }

          .token-box-3 {
            width: 100%;
            max-width: 450px;
            margin-inline: auto;

            .main-box {
              width: 345px;
            }

            label {
              margin-bottom: 20px;
              font-size: 19px;
            }

            input[type=text] {
              font-size: 19px;
            }

            .token-balance {
              .token-currency {
                .bg-logo {
                  width: 23px;
                  height: 23px;
                  margin-inline-end: 12px;
                }
              }
            }

          }

          .token-button {
            margin-top: 30px;

            span {
              font-size: 15px;
            }

            .bg-button {
              max-width: 260px;
            }
          }
        }
      }

    }

    img.swap_frame {
      height: 100%;
      max-width: 980px;
      position: absolute;
      top: 0px;
      z-index: -1;
      object-fit: cover;
      left: 48.4%;
      transform: translateX(-50%);
    }
  }

  .nfts {
    .nfts-container {
      .scroll-border {
        .scroll {
          overflow: auto;
          max-height: unset;
          padding: 0px 10px;
          margin: 0px;
        }
      }

      .search-box-content {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

        .empty-space {
          width: 100%;
        }

        .search-bar-box {
          width: 100%;

          .search-bar {
            width: 100%;
            justify-content: center;

          }
        }

        .nfts-button {
          display: block;
          width: 100%;
        }
      }

      .nfts-content-area {
        .nfts-box-1 {
          .nfts-border-1 {

            .nfts-input-box {

              .nfts-sub-input-box {
                height: 80px;
              }

              .nfts-volume-box {
                .volume-box-1 {
                  width: 50px;
                }

                .volume-box-2 {
                  .span-1 {
                    font-size: 23px;
                    margin-bottom: 7px;
                  }

                  .span-2 {
                    font-size: 14px;
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  .gridlistview {
    .farming-container {
      .scroll-border {
        margin: 30px auto;

        .scroll-shadow {
          display: none;
        }

        .scroll {
          max-height: unset;
          margin: 0px;
          padding-inline: 0px;

          .farming-content-area {
            .farming-box-1 {
              &::after {
                left: -4px;
                width: 55px;
                height: 135px;
              }

              .frame-sub-box-1 {
                font-size: 20px;
              }

              .frame-sub-box-2 {
                padding-inline: 10px;

                .frame-input-sub-1 {

                  .span-area-1 {
                    font-size: 16px;
                  }

                  .span-area-2 {
                    font-size: 15px;
                  }
                }

                .frame-input-sub-2 {
                  margin-top: 17px;

                  .span-area-3 {
                    p {
                      font-size: 17px;

                      .span-number {
                        font-size: 16px;
                      }
                    }
                  }

                  .span-area-4 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }

                .frame-input-sub-3 {
                  margin-top: 17px;

                  .span-area-5 {
                    p {
                      font-size: 12px;

                      .span-letter {
                        font-size: 19px;
                        margin-block: 7px;
                      }
                    }
                  }

                  .span-area-6 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }
              }

              .frame-sub-box-3 {
                margin-block: 20px;

                button {
                  span {
                    font-size: 14px;
                  }

                  .bg-button {
                    max-width: 210px;
                  }
                }
              }
            }
          }
        }
      }

      .search-box-content {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

        .search-bar-box {
          width: 100%;

          .search-bar {
            width: 100%;
            justify-content: center;

            label {
              input[type=search] {
                font-size: 18px;
              }

            }
          }
        }

        .nfts-button {
          display: block;
          width: 100%;

          .button-1 {
            max-width: 210px;
            margin-inline: auto;

            span {
              font-size: 15px;
            }
          }
        }
      }

      .farming-button-area {
        justify-content: space-around;

        .flex-btn-group {
          .view-btn-group {
            .list-btn {
              max-width: 50px;

              button {
                width: 100%;
              }
            }

            .gird-btn {
              max-width: 50px;

              button {
                width: 100%;
              }
            }
          }

          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                button {
                  padding: 8px 19px;
                }

                ul {
                  li {
                    padding: 10px 14px;
                    font-size: 14px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .empty-area {
          width: 0px;
        }
      }

      .farming-outer-button-border-1 {

        .farming-inner-button-border {
          .farming-button-grp {
            ul {
              li {
                padding: 10px 16px;
                font-size: 14px;

                .dropdown-item {
                  padding: 10px;
                }
              }
            }

            button {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        padding: 0px;
        margin: 10px 15px;

        .farming-content-area {
          .farming-box-1 {
            max-height: 84px;
            width: 100%;

            .frame-sub-box-1 {
              flex: 0 0 30%;
              font-size: 17px;
            }

            .frame-sub-box-2 {
              padding-inline: 10px;
              flex: 0 0 70%;

              .frame-input-sub-1 {
                flex: 0 0 73%;
                margin-top: 10px;

                .span-area-1 {
                  font-size: 16px;
                }

                .span-area-2 {
                  font-size: 16px;
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 0px;

                button {
                  max-width: 70px;
                  height: 100%;
                  display: block;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 0px;
                flex: 0 0 50%;

                .span-area-3 {
                  p {
                    font-size: 14px;

                    .span-number {
                      font-size: 14px;
                    }
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 50%;

                .span-area-5 {
                  p {
                    font-size: 12px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 16px;
                      margin-block: 4px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              button {
                max-width: 330px;

                span {
                  font-size: 18px;
                }
              }

            }

          }

        }
      }
    }
  }

  .slideBoxMain {
    margin: 30px auto 0px;

    .slideMainPages {
      flex-basis: calc(100% - 50px);
      max-width: calc(100% - 50px);
      order: 1;
      padding: 0px 10px 290px;
      margin: 0px auto 0px;
      border-top: 7px solid #01ffff;
      border-left: 5px solid #01ffff;
      border-right: 5px solid #01ffff;
      position: relative;
      border-radius: 15px 15px 0px 0px;

      &::before {
        content: "";
        position: sticky;
        top: 0px;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(black, transparent);
        display: block;
        z-index: 999;
      }
    }

    .slideMainCir {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 48px);
      max-width: calc(100% - 48px);
      margin: 0 auto;
      order: 0;
      padding: 0px;
      z-index: 99999;
      background: #000000;
      width: 100%;
      top: unset;
      height: 290px;
      bottom: 0px;
      position: fixed;
      left: 50%;
      transform: translateX(-50%) scaleY(0);
      transform-origin: bottom center;

      &.fixed {
        transform: translateX(-50%) scaleY(1);
      }

      &::before {
        content: "";
        position: absolute;
        top: -90px;
        left: 50%;
        width: calc(100% - 45px);
        height: 100px;
        background-image: linear-gradient(transparent, #000000);
        z-index: 1;
        transform: translateX(-50%);
      }

      .slideMainCirInner {
        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 50vw;
          background-color: #01ffff;
          transform-origin: top left;
          transform: rotate(-50deg);
          z-index: -1;
          top: 0;
          left: 0;
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 50vw;
          background-color: #01ffff;
          transform-origin: top right;
          transform: rotate(50deg);
          z-index: -1;
          top: 0;
          right: 0;
        }

        .slideText {
          top: 85px;
          left: calc(50% - 50px);
          right: unset;
        }

        .slideSwapCirBack {
          max-width: 400px;
          transform: rotate(4deg);
        }
      }
    }
  }

  .swap .swap-container .swap-main-box {
    padding: 0px;
  }

  .nfts .nfts-container .nfts-button .button-1 {
    margin-left: auto;
    margin-right: auto;
  }

  .nfts .nfts-container .scroll-border .scroll-shadow {
    display: none;
  }

  .gridlistview.listviewmode .farming-container .farming-content-area .farming-box-1 {
    margin: 0 0 15px;
  }

  .hiddenMob {
    display: none !important;
  }

  .slideBoxMain .slideMainCir .slideMainCirInner {
    margin-bottom: 0px;
    padding-top: 40px;
    overflow: hidden;
    height: 290px;
  }

  .slideBoxMain .slideMainCir svg {
    max-width: 180px;
    top: calc(50% + 97px);
    left: calc(50% - 10px);
  }

  .slideBoxMain .slideMainCir .slideMainCirInner .swapLogo {
    top: calc(50% + 97px);
    left: calc(50% - 10px);
    width: 60px;
  }

  .swap .swap-container button {
    margin-right: auto;
  }

  .middelBox .oc_col12 {
    margin-bottom: 0px;
  }

  .modal.newPositionMdl .modal-dialog {
    max-width: calc(100% - 20px);
    width: 550px;
  }

  .modal.newPositionMdl .modal-dialog .modal-header {
    padding: 20px 15px 20px 10px;
  }

  .modal.newPositionMdl .popMaininrBox .col-lg-6 {
    padding: 10px 10px;
  }

  .modal.newPositionMdl .selectPairBox .btn-group {
    &:nth-child(1) {
      padding-right: 5px;
    }

    &:nth-child(2) {
      padding-left: 5px;
    }

    button {
      padding: 10px 5px 10px 10px;
      font-size: 14px;
    }
  }

  .modal.newPositionMdl .bestForBox .bstfrbxInr {
    padding: 5px;
  }

  .modal.newPositionMdl .bestForBox {
    margin-top: 15px;
  }

  .modal.newPositionMdl .modal-dialog .modal-header .modal-title {
    font-size: 14px;
    padding-left: 0px;
  }

  .modal.newPositionMdl .setPriceRangeBx p {
    font-size: 16px;
  }

  .modal.newPositionMdl .titileBox {
    text-align: center;
  }

  .nftLiquidity .topheadTtl {
    justify-content: center;
    flex-wrap: wrap;
  }

  .nftLiquidity .topheadTtl h2 {
    margin: 0px 0px 15px;
  }

  .nftLiquidity .col-12.col-lg-6 {
    margin-bottom: 20px;
  }
}

@media (max-width:768px) {
  .dashboard {
    .oc_row {
      .oc_col6 {
        .boxTopLeft {

          .chartBox {
            flex: 0 0 100%;
            max-width: 90%;
          }

          .chartData {
            flex: 0 0 100%;
            max-width: 90%;

            span {
              right: 0px;
              font-size: 15px;
            }
          }
        }

        .boxTopRight {

          .oc_row {

            .chartData {
              width: 322px;
              position: relative;
              flex: 0 0 75%;
              max-width: 75%;
              display: none;
              height: 133px;

              img {
                width: 100%;
                height: 100%;
                position: relative;
                left: -160px;
              }

              span {
                display: none;
              }
            }

            .progrfour {
              flex: 0 0 70%;
              max-width: 65%;

            }

            .progressCirclebox {
              margin-bottom: 6px;
              padding: 0px 5px;

              span {
                font-size: 14px;
              }

            }
          }

          .boxline {
            left: 44%;
            margin-top: 100px;

            &::after {
              font-size: 19px;
            }

          }
        }
      }
    }

    .oc_row {
      .circleBox {
        margin-top: -5vw;

        .infobox {
          margin-block: 2.5vw;

          .Typewriter {
            font-size: 4vw;
            // font-size: 2.3vw;
          }

        }
      }
    }

    .oc_row {
      .oc_mobile {
        margin-top: 4.5vw;

        .innerbox {
          max-height: 91px;
          min-height: 96px;
        }
      }

      .boxBottomLeft {
        max-width: 250px;

      }

      .boxBottomRight {
        // max-width: 250px;
        width: 100%;

        .border_line {
          width: 100%;

          .progressbox {
            width: 100%;

            .progressboxinr {
              p {
                text-align: end;
              }

              .progress {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                span {
                  margin-right: 10px;
                }
              }
            }
          }
        }

      }
    }
  }

  .swap {
    img.swap_frame {
      top: 41px;
    }
  }

  .nfts {
    img.swap_frame {
      left: 47%;
    }
  }

  .gridlistview {
    .farming-container {
      .scroll-border {
        .scroll {
          .farming-content-area {
            .farming-box-1 {
              &::after {
                left: -4px;
                width: 55px;
                height: 135px;
              }

              .frame-sub-box-1 {
                font-size: 20px;
              }

              .frame-sub-box-2 {
                padding-inline: 10px;

                .frame-input-sub-1 {

                  .span-area-1 {
                    font-size: 16px;
                  }

                  .span-area-2 {
                    font-size: 15px;
                  }
                }

                .frame-input-sub-2 {
                  margin-top: 25px;

                  .span-area-3 {
                    p {
                      font-size: 17px;

                      .span-number {
                        font-size: 16px;
                      }
                    }
                  }

                  .span-area-4 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }

                .frame-input-sub-3 {
                  margin-top: 25px;

                  .span-area-5 {
                    p {
                      font-size: 12px;

                      .span-letter {
                        font-size: 19px;
                        margin-block: 7px;
                      }
                    }
                  }

                  .span-area-6 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }
              }

              .frame-sub-box-3 {
                margin-block: 20px;

                button {
                  span {
                    font-size: 14px;
                  }

                  .bg-button {
                    max-width: 210px;
                  }
                }
              }
            }
          }
        }
      }

      .search-box-content {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

        .search-bar-box {
          margin-top: 0px;

          .search-bar {
            width: 100%;
            justify-content: flex-start;

            label {
              input[type=search] {
                font-size: 18px;
              }
            }
          }
        }

        .nfts-button {
          display: block;
          width: 100%;

          .button-1 {
            max-width: 210px;

            span {
              font-size: 15px;
            }
          }
        }
      }

      .farming-button-area {
        justify-content: space-around;

        .flex-btn-group {
          .view-btn-group {
            .list-btn {
              max-width: 45px;

              button {
                width: 100%;
              }
            }

            .gird-btn {
              max-width: 45px;

              button {
                width: 100%;
              }
            }
          }

          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                ul {
                  li {
                    padding: 10px 14px;
                    font-size: 14px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .empty-area {
          width: 0px;
        }

        .farming-outer-button-border-1 {
          .farming-inner-button-border {
            .farming-button-grp {
              ul {
                li {
                  padding: 10px 12px;
                  font-size: 14px;

                  .dropdown-item {
                    padding: 10px;
                  }
                }
              }

              button {
                font-size: 15px;
                padding: 8px 15px;
              }
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        padding: 0px;
        margin: 10px 15px;

        .farming-content-area {
          .farming-box-1 {
            max-height: 75px;
            width: 100%;

            .frame-sub-box-1 {
              flex: 0 0 10%;
              font-size: 14px;
            }

            .frame-sub-box-2 {
              padding-inline: 10px;
              flex: 0 0 64%;
              align-items: center;

              .frame-input-sub-1 {
                flex: 0 0 73%;
                margin-top: 11px;

                .span-area-1 {
                  font-size: 14px;
                }

                .span-area-2 {
                  font-size: 14px;
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 0px;

                button {
                  max-width: 70px;
                  height: 100%;
                  display: block;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 25px;
                flex: 0 0 100%;

                .span-area-3 {
                  p {
                    font-size: 14px;

                    .span-number {
                      font-size: 14px;
                    }
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 0 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 100%;

                .span-area-5 {
                  p {
                    font-size: 12px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 14px;
                      margin-block: 4px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 0 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              button {
                max-width: 260px;

                span {
                  font-size: 14px;
                }
              }

            }

          }

        }
      }
    }
  }

  .slideBoxMain .slideMainCir .slideMainCirInner::before {
    height: 60vw;
    transform: rotate(-33deg);
  }

  .slideBoxMain .slideMainCir .slideMainCirInner::after {
    height: 60vw;
    transform: rotate(33deg);
  }

  .nfts .nfts-container .nfts-outer-button-border {
    left: 0px;
    flex: 0 0 100%;
    margin: 0 auto;
    order: 1;
  }

  .nfts .nfts-container .search-box-content {
    flex: 0 0 100%;
    max-width: 100%;
    order: 0;
  }

  .nfts .nfts-container .search-box-content .search-bar-box {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .nftsHeader {
    top: -70px;
  }

  .gridlistview .farming-container .search-box-content {
    flex: 0 0 calc(100% - 145px);
    max-width: calc(100% - 145px);
    order: 0;
  }

  .gridlistview .farming-container .farming-button-area .flex-btn-group {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    justify-content: space-between;
    margin-top: 30px;
  }

  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group {
    width: 100px;
  }

  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border {
    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }

  .gridlistview .farming-container .farming-button-area {
    background-image: linear-gradient(#000000 94%, rgba(255, 0, 0, 0));
  }

  .connectBtnMain.poolsBoxHead h2 {
    font-size: 16px;
  }

  .poolsBox {
    padding: 25px 20px;
  }

  .nfts .nfts-container button {
    max-width: 220px;
    margin-left: 10px;
  }

  .nfts .nfts-container button span {
    font-size: 14px;
  }

  .poolsBox .poolsBoxInner {
    padding: 15px;
  }

  .gridlistview .farming-container .farming-content-area {
    margin: 20px auto 0px;
  }

  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }

  .yourPositionBox .headTitle {
    padding: 15px 10px;
  }

  .yourPositionBox .rowBoxItems {
    padding: 15px 10px;
  }

  .yourPositionBox .topheadTtl img {
    width: 22px;
    height: 22px;
  }

  .yourPositionBox .topheadTtl strong {
    font-size: 14px;
  }

  .yourPositionBox .topheadTtl span {
    font-size: 12px;
  }

  .yourPositionBox p {
    font-size: 12px;
    margin-left: -5px;
  }

}

@media (max-width:567px) {
  .dashboard {
    .abc {
      max-height: 50px !important;
    }

    .oc_row {
      .oc_col6 {
        .boxTopLeft {
          .chartBox {
            flex: 0 0 100%;
            max-width: 100%;
          }

          .chartData {
            flex: 0 0 100%;
            max-width: 100%;

            img {
              min-height: 90px;
            }

            span {
              right: 0px;
              font-size: 13px;
              top: 50px;
            }
          }
        }

        .boxTopRight {
          .oc_row {
            .chartData {
              width: 322px;
              position: relative;
              flex: 0 0 100%;
              max-width: 90%;
              display: none;
              height: 133px;

              img {
                width: 100%;
                height: 100%;
                position: relative;
                left: -160px;
              }

              span {
                display: none;
              }
            }

            .progrfour {
              flex: 0 0 100%;
              max-width: 90%;

            }

            .progressCirclebox {
              margin-bottom: 6px;
              padding: 0px 5px;

              h3 {
                font-size: 13px;
              }
            }
          }

          .boxline {
            left: 21%;
            top: 46;
            margin-top: 100px;

            &::after {
              font-size: 14px;
              left: 27px;
            }
          }
        }
      }
    }

    .oc_row {
      .circleBox {
        margin-top: -2vw;

        .infobox {
          margin-block: 2.5vw;

          .Typewriter {
            // font-size: 5vw;
            font-size: 4.2vw;
          }

        }
      }
    }

    .oc_row {
      .oc_mobile {
        margin-top: 7.5vw;

        .innerbox {
          max-height: 91px;
          min-height: 96px;

        }
      }

      .boxBottomLeft {
        max-width: 250px;

        .progressbox {
          width: 100%;

          .progressboxinr {
            margin-bottom: 15px;

            p {
              font-size: 12px;

            }

            .progress {
              height: 18px;
            }
          }
        }

      }

      .boxBottomRight {
        max-width: 250px;
        width: 100%;

        .border_line {
          width: 100%;

          .progressbox {
            width: 100%;

            .progressboxinr {
              margin-bottom: 15px;

              p {
                font-size: 11px;

              }

              .progress {
                height: 18px;
              }
            }
          }
        }

      }
    }
  }

  .swap {
    .swap-container {
      .button-1 {
        max-width: 183px;

        span {
          font-size: 14px;
        }
      }

      .button-1:hover {
        box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 6px 3px #00d7c3;
      }

      .swap-main-box {
        .input-boxes {
          .token-box-1 {
            max-width: 400px;

            .main-box {
              width: 300px;
            }

            label {
              margin-bottom: 20px;
              font-size: 17px;
            }

            input[type=text] {
              font-size: 17px;
            }

            .token-balance {
              .token-currency {
                .bg-logo {
                  width: 23px;
                  height: 23px;
                  margin-inline-end: 12px;
                }
              }
            }
          }

          .token-box-2 {
            max-width: 55px;
            margin: 0 auto;
          }

          .token-box-3 {
            width: 100%;
            max-width: 400px;
            margin-inline: auto;

            .main-box {
              width: 300px;
            }

            label {
              margin-bottom: 20px;
              font-size: 17px;
            }

            input[type=text] {
              font-size: 17px;
            }

            .token-balance {
              .token-currency {
                .bg-logo {
                  width: 23px;
                  height: 23px;
                  margin-inline-end: 12px;
                }
              }
            }

          }

        }
      }

    }
  }

  .nfts {
    .nfts-container {
      .nfts-button {
        .button-1 {
          max-width: 183px;
          margin-inline: auto;

          span {
            font-size: 14px;
          }
        }

        .button-1:hover {
          box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 6px 3px #00d7c3;
        }
      }

      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 15px;
            }

            &::after {
              top: -23px;
            }

          }
        }
      }

      .nfts-outer-button-border {
        .nfts-inner-button-border {
          .nfts-button-grp {
            ul {
              li {
                padding: 10px 10px;
                font-size: 12px;
              }
            }
          }
        }
      }

      .nfts-content-area {
        .nfts-box-1 {
          .nfts-border-1 {

            .nfts-input-box {

              .nfts-volume-box {
                margin: 0 23px 0 20px;


                .volume-box-2 {

                  .span-1 {
                    font-size: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }

    img.swap_frame {
      left: 47%;
    }
  }

  .gridlistview {
    .farming-container {
      .scroll-border {
        .scroll {
          .farming-content-area {
            .farming-box-1 {
              &::after {
                left: -4px;
                width: 55px;
                height: 135px;
              }

              .frame-sub-box-1 {
                font-size: 20px;
              }

              .frame-sub-box-2 {
                padding-inline: 10px;

                .frame-input-sub-1 {

                  .span-area-1 {
                    font-size: 16px;
                  }

                  .span-area-2 {
                    font-size: 15px;
                  }
                }

                .frame-input-sub-2 {
                  margin-top: 25px;

                  .span-area-3 {
                    p {
                      font-size: 17px;

                      .span-number {
                        font-size: 16px;
                      }
                    }
                  }

                  .span-area-4 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }

                .frame-input-sub-3 {
                  margin-top: 25px;

                  .span-area-5 {
                    p {
                      font-size: 12px;

                      .span-letter {
                        font-size: 19px;
                        margin-block: 7px;
                      }
                    }
                  }

                  .span-area-6 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }
              }

              .frame-sub-box-3 {
                margin-block: 20px;

                button {
                  span {
                    font-size: 14px;
                  }

                  .bg-button {
                    max-width: 210px;
                  }
                }
              }
            }
          }
        }
      }

      .search-box-content {
        .search-bar-box {
          .search-bar {
            label {
              input[type=search] {
                font-size: 15px;
              }
            }
          }
        }

        .nfts-button {
          display: block;
          width: 100%;

          .button-1 {
            max-width: 183px;
            margin-inline: auto;

            span {
              font-size: 14px;
            }
          }

          .button-1:hover {
            box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 6px 3px #00d7c3;
          }
        }
      }

      .farming-button-area {
        .flex-btn-group {
          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                ul {
                  li {
                    padding: 10px 10px;
                    font-size: 14px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .empty-area {
          width: 0px;
        }

        .farming-outer-button-border-1 {
          .farming-inner-button-border {
            .farming-button-grp {
              ul {
                li {
                  padding: 10px 10px;
                  font-size: 13px;

                  .dropdown-item {
                    padding: 3px;
                  }
                }
              }

              button {
                font-size: 13px;
                padding: 8px 10px;
              }
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        padding: 0px;
        margin: 10px;

        .farming-content-area {
          .farming-box-1 {
            max-height: 75px;
            width: 100%;

            &::before {
              width: 36px;
              height: 57px;
            }

            &::after {
              width: 42px;
              height: 90px;
              left: -7px;
              top: -5px;
            }

            .frame-sub-box-1 {
              flex: 0 0 10%;
              font-size: 14px;
              padding: 12px;

              .farming-logo-box {
                svg {
                  height: 100%;
                  max-width: 30px;
                }
              }
            }

            .frame-sub-box-2 {
              padding-inline: 0px;
              flex: 0 0 68%;
              align-items: center;

              .frame-input-sub-1 {
                flex: 0 0 73%;
                margin-top: 11px;

                .span-area-1 {
                  font-size: 13px;
                }

                .span-area-2 {
                  font-size: 13px;

                  p {
                    img {
                      display: none;
                    }
                  }
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 0px;

                button {
                  max-width: 57px;
                  height: 100%;
                  display: block;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 25px;
                flex: 0 0 100%;

                .span-area-3 {
                  p {
                    font-size: 14px;

                    .span-number {
                      font-size: 14px;
                    }
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 0 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 100%;

                .span-area-5 {
                  p {
                    font-size: 12px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 14px;
                      margin-block: 4px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 0 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              button {
                max-width: 260px;

                span {
                  font-size: 14px;
                }
              }

            }

          }

        }
      }
    }
  }

  .boxTopLeft .chartBox::before,
  .boxTopLeft .chartBox::after {
    width: 25px;
  }

  .gridlistview:not(.listviewmode) .farming-container .farming-content-area .farming-box-1 {
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  .gridlistview .farming-container .scroll-border {
    margin: 0px auto;
  }

  .nfts .nfts-container .nfts-content-area .nfts-box-1 {
    flex-basis: calc(100% - 0px);
    max-width: calc(100% - 0px);
    margin: 0px 0px 20px;
  }

  .slideBoxMain .slideMainPages {
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }

  .slideBoxMain .slideMainCir {
    flex-basis: calc(100% - 19px);
    max-width: calc(100% - 19px);
  }

  .nfts .nfts-container .nfts-outer-button-border {
    padding: 2px 5px;
  }

  .modal.previewModalBox .topheadTtl strong {
    font-size: 16px;
  }

  .modal.previewModalBox .feeTierTable {
    padding: 15px;
  }

  .modal.previewModalBox .feeTierTable .rowBx:nth-last-child(1) strong {
    font-size: 18px;
  }

  .modal.previewModalBox .selectedRangeBox ul h2 {
    font-size: 16px;
  }

  .modal.previewModalBox .selectedRangeBox ul .tabmenu li {
    font-size: 14px;
  }

  .yourPositionBox p {
    text-align: center;
    margin-top: 20px;
  }

  .yourPositionBox p svg {
    width: 22px;
    display: block;
    margin: 6px auto;
    transform: rotate(90deg);
  }

  .yourPositionBox .headTitle {
    flex-wrap: wrap;
  }

  .yourPositionBox .headTitle h2 {
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .returnSwapbox h2 {
    font-size: 20px;
  }

  .returnSwapbox p {
    font-size: 12px;
  }

  .stakeModalPopup {
    .tokenDiv {
      font-size: 14px !important;
    }

    .form-group.flex {
      justify-content: center;

      label {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .token-balance {
      text-align: center;
      justify-content: center;
      flex-direction: row !important;
    }
  }
}

@media (max-width:500px) {
  .tokenDiv {
    font-size: 11px !important;
  }

  .swap .swap-container .swap-main-box .token-box-1 .form-group input[type=number] {
    font-size: 16px !important;
  }

  .swap .swap-container .swap-main-box .token-box-3 .form-group input[type=number] {
    font-size: 16px !important;
  }

  .dashboard {
    .abc {
      max-height: 40px !important;
    }

    .oc_row {
      .oc_col6 {


        .boxTopLeft {

          .chartBox {
            p {
              // font-size: 14px;
              font-size: 3.5vw;
            }
          }

          .chartData {
            img {
              min-height: 50px;
            }

            span {
              right: 0px;
              font-size: 9px;
              top: unset;
              bottom: 10px;
            }
          }

        }

        .boxTopRight {

          .oc_row {

            .chartData {
              flex: 0 0 100%;
              max-width: 100%;
            }

            .progrfour {
              flex: 0 0 100%;
              max-width: 93%;

            }

            .progressCirclebox {
              margin-bottom: 6px;
              padding: 0px 3px;

              h3 {
                font-size: 9px;
              }

              span {
                font-size: 10px;
              }

            }
          }

          .boxline {
            left: 50%;
            // top: 36%;
            top: 20vw;
            margin-top: 100px;

            &::after {
              font-size: 12px;
              left: 14px;
            }

          }
        }
      }
    }

    .oc_row {
      .circleBox {
        min-width: 270px;
        min-height: 270px;
        max-height: 280px;

        .centerlogo {
          min-width: 90px;
          min-height: 90px;
        }

        .infobox {
          margin-block: 2.5vw;

          .Typewriter {
            // font-size: 5vw;
            font-size: 4.2vw;
          }

        }
      }
    }

    .oc_row {
      .oc_mobile {
        margin-top: 8.5vw;

        .innerbox {
          max-height: 91px;
          min-height: 96px;
          padding-inline: 5px;

        }
      }

      .boxBottomLeft {
        .progressbox {
          .progressboxinr {
            margin-bottom: 12px;

            p {
              font-size: 11px;

            }

            .progress {
              height: 16px;

              span {
                font-size: 14px;
              }
            }
          }
        }

      }

      .boxBottomRight {

        .border_line {
          .progressbox {
            .progressboxinr {
              margin-bottom: 12px;

              p {
                font-size: 11px;

              }

              .progress {
                height: 16px;

                span {
                  font-size: 14px;
                }
              }
            }
          }
        }

      }
    }
  }

  .swap {
    .swap-container {
      .button-1 {
        span {
          font-size: 14px;
        }
      }

      .swap-main-box {
        .input-boxes {
          &::after {
            bottom: 3px;
            max-width: 3px;
            right: -1px;
          }

          &::before {
            bottom: 1px;
            max-width: 3px;
            left: -3px;
          }

          .token-box-1 {
            // max-width: 260px;
            max-width: 100%;

            .main-box {
              // width: 200px;
              max-width: calc(100% - 40px);

              label {
                margin-bottom: 9px;
                font-size: 14px;
              }

              input[type=text] {
                font-size: 14px;
              }
            }

            .token-balance {
              .token-currency {
                .bg-logo {
                  width: 19px;
                  height: 19px;
                  margin-inline-end: 10px;
                }

                span {
                  font-size: 12px;
                }
              }
            }
          }

          .token-box-2 {
            max-width: 100px;
            margin-top: 5px;
            margin-bottom: 5px;

            svg {
              width: 70px;
            }
          }

          .token-box-3 {
            // max-width: 260px;
            max-width: 100%;
            margin-inline: auto;

            .main-box {
              // width: 200px;
              max-width: calc(100% - 40px);
            }

            label {
              margin-bottom: 9px;
              font-size: 14px;
            }

            input[type=text] {
              font-size: 14px;
            }

            .token-balance {
              .token-currency {
                .bg-logo {
                  width: 19px;
                  height: 19px;
                  margin-inline-end: 10px;
                }

                span {
                  font-size: 12px;
                }
              }
            }

          }

          .token-button {
            margin-top: 20px;

            span {
              font-size: 14px;
            }

            .bg-button {
              max-width: 250px;
            }
          }
        }
      }

      .button-1 {
        span {
          font-size: 11px;
        }
      }
    }

    img.swap_frame {
      top: 30px;
      max-width: 547px;
    }
  }

  .nfts {
    .nfts-container {
      .scroll::-webkit-scrollbar {
        width: 3px;
      }

      .nfts-button {
        .button-1 {
          max-width: 140px;
          margin-inline: auto;

          span {
            font-size: 11px;
          }
        }
      }

      .search-box-content {
        .search-bar {
          label {
            input[type=search] {
              font-size: 15px;
            }

            &::after {
              top: -23px;
            }

          }
        }
      }

      .scroll-border {
        &::after {
          rotate: 221deg;
        }

        &::before {
          rotate: 142deg;
        }
      }

      .nfts-content-area {
        .nfts-box-1 {
          .nfts-border-1 {

            .nfts-input-box {

              .nfts-sub-input-box {
                height: 60px;

              }

              .nfts-volume-box {
                margin: 0 10px 0 8px;

                .volume-box-1 {
                  width: 40px;
                }

                .volume-box-2 {
                  .span-1 {
                    font-size: 18px;
                  }

                  .span-2 {
                    font-size: 13px;
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  .gridlistview {
    .farming-container {
      .scroll-border {
        .scroll {
          .farming-content-area {
            .farming-box-1 {
              width: 260px;

              &::before {
                width: 36px;
                height: 57px;
              }

              &::after {
                width: 42px;
                height: 90px;
                left: -7px;
                top: -5px;
              }

              .frame-sub-box-1 {
                font-size: 20px;
              }

              .frame-sub-box-2 {
                padding-inline: 5px;

                .frame-input-sub-1 {

                  .span-area-1 {
                    font-size: 14px;
                  }

                  .span-area-2 {
                    font-size: 14px;
                  }
                }

                .frame-input-sub-2 {
                  margin-top: 15px;

                  .span-area-3 {
                    p {
                      font-size: 14px;

                      .span-number {
                        font-size: 14px;
                      }
                    }
                  }

                  .span-area-4 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }

                .frame-input-sub-3 {
                  margin-top: 15px;

                  .span-area-5 {
                    p {
                      font-size: 12px;

                      .span-letter {
                        font-size: 16px;
                        margin-block: 5px;
                      }
                    }
                  }

                  .span-area-6 {
                    max-width: fit-content;

                    button {
                      font-size: 14px;
                    }
                  }
                }
              }

              .frame-sub-box-3 {
                margin-block: 20px;

                button {
                  span {
                    font-size: 12px;
                  }

                  .bg-button {
                    max-width: 190px;
                  }
                }
              }
            }
          }
        }
      }

      .search-box-content {
        .nfts-button {
          display: block;
          width: 100%;

          .button-1 {
            max-width: 140px;
            margin-inline: auto;

            span {
              font-size: 11px;
            }
          }
        }
      }

      .farming-button-area {
        .flex-btn-group {
          .farming-outer-button-border {
            .farming-inner-button-border {
              .farming-button-grp {
                ul {
                  li {
                    padding: 10px 10px;
                    font-size: 12px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .empty-area {
          width: 0px;
        }

        .farming-outer-button-border-1 {
          .farming-inner-button-border {
            .farming-button-grp {
              ul {
                li {
                  padding: 10px 10px;
                  font-size: 12px;

                  .dropdown-item {
                    padding: 3px;
                  }
                }
              }

              button {
                font-size: 12px;
                padding: 8px 10px;
              }
            }
          }
        }
      }
    }
  }

  .gridlistview.listviewmode .farming-container {
    .scroll-border {
      .scroll {
        padding: 0px;
        margin: 10px;

        .farming-content-area {
          .farming-box-1 {
            max-height: 66px;
            width: 100%;

            &::before {
              width: 36px;
              height: 57px;
            }

            &::after {
              width: 42px;
              height: 90px;
              left: -7px;
              top: -5px;
            }

            .frame-sub-box-1 {
              flex: 0 0 50%;
              font-size: 15px;
              padding: 0;
              margin-right: auto;
              margin-top: 15px;

              .farming-logo-box {
                margin-right: 11px;
                margin-left: 3px;

                svg {
                  height: 100%;
                  max-width: 30px;
                }
              }
            }

            .frame-sub-box-2 {
              padding-inline: 0px;
              flex: 0 0 90%;
              align-items: center;

              .frame-input-sub-1 {
                flex: 0 0 100%;
                margin-top: 11px;
                order: 2;

                .span-area-1 {
                  font-size: 13px;
                }

                .span-area-2 {
                  font-size: 13px;

                  p {
                    img {
                      display: none;
                    }
                  }
                }
              }

              .frame-input-sub-button {
                display: flex;
                align-items: center;
                margin-inline-start: 0px;
                flex: 0 0 100%;
                margin-top: -26px;

                button {
                  max-width: 57px;
                  height: 100%;
                  display: block;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

              }

              .frame-input-sub-2 {
                justify-content: space-between;
                margin-top: 25px;
                flex: 0 0 100%;
                order: 3;

                .span-area-3 {
                  p {
                    font-size: 14px;

                    .span-number {
                      font-size: 14px;
                    }
                  }
                }

                .span-area-4 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 0 0 auto;
                  }
                }
              }

              .frame-input-sub-3 {
                flex: 0 0 100%;
                order: 4;

                .span-area-5 {
                  p {
                    font-size: 12px;
                    line-height: 1.5rem;

                    .span-letter {
                      font-size: 14px;
                      margin-block: 4px;
                    }
                  }
                }

                .span-area-6 {
                  height: auto;
                  max-width: initial;

                  button {
                    font-size: 14px;
                  }

                  .harvest-bg {
                    width: fit-content;
                    margin: 0 0 0 auto;
                  }
                }
              }

            }

            .frame-sub-box-3 {
              button {
                max-width: 260px;

                span {
                  font-size: 14px;
                }
              }

            }

          }

        }
      }
    }
  }

  .middelBox .oc_col12 {
    margin-top: 35px;
  }

  .swap .swap-container .swap-main-box .swapNetwork .Collapsible__trigger {
    padding-left: 28px;
    font-size: 14px;
  }

  .swap .swap-container .swap-main-box .swapNetwork .infoIcon {
    top: -2px;
    width: 20px;
  }

  .swap .swap-container .swap-main-box .swapNetwork .topBtnArrow {
    width: 18px;
  }

  .swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter .Collapsible__contentInner p span {
    font-size: 12px;
  }

  .swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter .Collapsible__contentInner p span {
    font-size: 12px;
  }

  .nftLiquidity .topheadTtl h2 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nftLiquidity .topheadTtl .btnGrp {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nftLiquidity .topheadTtl .btnGrp button {
    margin-top: 10px;
  }

  .nftLiquidity .liquidityTable .rowBoxTbl {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nftLiquidity .liquidityTable .rowBoxTbl .imgBx {
    margin: 0px 0px 6px;
  }

  .nftLiquidity .liquidityBox h2 {
    font-size: 26px;
  }

  .removeLiqBx .amountBox .countBox ul {
    flex-wrap: wrap;
    margin-left: 10px;
  }

  .removeLiqBx .amountBox .countBox ul li {
    padding: 4px 6px;
    font-size: 14px;
    flex: auto;
    margin: 0px 5px 5px;
    text-align: center;
    max-width: calc(50% - 10px);
  }
}

@media (max-width:400px) {
  .slideBoxMain .slideMainCir .slideMainCirInner .slideSwapCirBack {
    max-width: 320px;
  }

  .slideBoxMain .slideMainCir .slideMainCirInner {
    height: 220px;
  }

  .slideBoxMain .slideMainCir {
    height: 220px;
  }

  .slideBoxMain .slideMainCir svg {
    max-width: 145px;
    top: calc(50% + 91px);
    left: calc(50% - 8px);
  }

  .slideBoxMain .slideMainCir .slideMainCirInner .swapLogo {
    top: calc(50% + 89px);
    left: calc(50% - 7px);
    width: 50px;
  }
}

/* container-media */
@media (min-width:992px) {
  .oc_row {
    display: flex;

    .oc_mobile {
      display: none;
    }
  }

  .hiddenDesk {
    display: none !important;
  }

  .modal.newPositionMdl .popMaininrBox {
    .row {
      .col-lg-6 {
        &:nth-child(1) {
          order: 0;
        }

        &:nth-child(2) {
          order: 1;
        }

        &:nth-child(3) {
          order: 3;
        }

        &:nth-child(4) {
          order: 2;
        }

        &:nth-child(5) {
          order: 4;
        }

        &:nth-child(6) {
          order: 5;
        }
      }
    }
  }
}

.tokenDiv {
  font-size: 22px;
}


.lottery-cupan button {
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 8px 5px 15px 0px #000;
}

.lottery-cupan {
  animation: 7s ease-in-out 0s infinite normal none running rotate;
}

@keyframes rotate {

  0%,
  100% {
    transform: rotate(-4deg);
  }

  25% {
    transform: rotate(4deg);
  }

  50% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(4deg);
  }
}

.ticketsBox {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-image: url('../images/poolbox/poolboxtopline.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-image: url('../images/poolbox/poolboxbottomline.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    z-index: -1;
  }
}

.border-\[\#383241\] {
  border-color: #383241 !important;
}

/* media end */